import React, {useContext, useEffect, useState} from 'react';
import {fetchMatchPlus} from "../../../../api";
import {AppContext} from "../../../../context/AppContext";
import dayjs from "dayjs";
import TableCell from "@mui/material/TableCell";
import {Paper} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import GoalsMPTable from "../tables/GoalsMPTable";
import CornersMPTable from "../tables/CornersMPTable";
import CardsMPTable from "../tables/CardsMPTable";
import PopupPremium from "../../../premium/popup-premium/PopupPremium";

function HomeMatchPlus(props) {
    const {i18String, isPro} = useContext(AppContext);

    const {matchTeamsDetail, tabType} = props;

    const [homeTeamData, setHomeTeamData] = useState(null);
    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [selectAllLeagues, setSelectAllLeagues] = useState(true);
    const [selectAllSeasons, setSelectAllSeasons] = useState(true);
    const [selectedSeasons, setSelectedSeasons] = useState([]);
    const [showHomeMatches, setShowHomeMatches] = useState(true);
    const [showAwayMatches, setShowAwayMatches] = useState(true);
    const [selectedLimit, setSelectedLimit] = useState(10);
    const [selectedOption, setSelectedOption] = useState('cards');
    const [isShownPopup, setIsShownPopup] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (matchTeamsDetail) {
                const homeData = await fetchMatchPlus(matchTeamsDetail.homeId);
                setHomeTeamData(homeData.data.data);

                // Başlangıçta tüm ligleri seçili yap
                const allLeagueIds = homeData.data.data?.leagues.map((league) => league.leagueId) || [];
                const allSeasonsIds = homeData.data.data?.seasons.map((season) => season.id) || [];
                setSelectedLeagues(allLeagueIds);
                setSelectedSeasons(allSeasonsIds);
            }
        };

        fetchData();
    }, [fetchMatchPlus, matchTeamsDetail.homeId]);

    // Handle "Tümünü Seç" checkbox değişimi
    const handleSelectAllLeagues = () => {
        if (!isPro) {
            setIsShownPopup(true);
            return;
        }
        if (!selectAllLeagues) {
            // Tüm ligleri seç
            setSelectedLeagues(homeTeamData?.leagues.map((league) => league.leagueId) || []);
        } else {
            // Tüm ligleri kaldır
            setSelectedLeagues([]);
        }
        setSelectAllLeagues(!selectAllLeagues);
    };
    // Tek bir checkbox'ın durumunu değiştir
    const handleLeagueChange = (leagueId) => {
        if (!isPro) {
            setIsShownPopup(true);
            return;
        }
        setSelectedLeagues((prevSelectedLeagues) => {
            if (prevSelectedLeagues.includes(leagueId)) {
                return prevSelectedLeagues.filter((id) => id !== leagueId);
            } else {
                return [...prevSelectedLeagues, leagueId];
            }
        });

        // Eğer tek tek seçim yapılıyorsa, "Tümünü Seç" checkbox'ını kontrol et
        const allLeagues = homeTeamData?.leagues.map((league) => league.leagueId) || [];
        const nextSelectedLeagues = selectedLeagues.includes(leagueId)
            ? selectedLeagues.filter((id) => id !== leagueId)
            : [...selectedLeagues, leagueId];

        setSelectAllLeagues(nextSelectedLeagues.length === allLeagues.length);
    };

    const handleSelectAllSeasons = () => {
        if (!isPro) {
            setIsShownPopup(true);
            return;
        }
        if (!selectAllSeasons) {
            // Tüm sezonları seç
            setSelectedSeasons(homeTeamData?.seasons.map((season) => season.id) || []);
        } else {
            // Tüm sezonları kaldır
            setSelectedSeasons([]);
        }
        setSelectAllSeasons(!selectAllSeasons);
    };

    const handleSeasonChange = (seasonId) => {
        if (!isPro) {
            setIsShownPopup(true);
            return;
        }
        setSelectedSeasons((prevSelectedSeasons) => {
            if (prevSelectedSeasons.includes(seasonId)) {
                return prevSelectedSeasons.filter((id) => id !== seasonId);
            } else {
                return [...prevSelectedSeasons, seasonId];
            }
        });

        // Eğer tek tek seçim yapılıyorsa, "Tümünü Seç" checkbox'ını kontrol et
        const allSeasons = homeTeamData?.seasons.map((season) => season.id) || [];
        const nextSelectedSeasons = selectedSeasons.includes(seasonId)
            ? selectedSeasons.filter((id) => id !== seasonId)
            : [...selectedSeasons, seasonId];

        setSelectAllSeasons(nextSelectedSeasons.length === allSeasons.length);
    };

    const handleLimitChange = (event) => {
        if (!isPro) {
            setIsShownPopup(true);
            return;
        }
        setSelectedLimit(Number(event.target.value)); // Seçilen değeri güncelle
    };

    const filteredMatches = homeTeamData?.matches
        .filter((match) => {
            // Lig ve sezon filtreleme
            const isLeagueSelected = selectedLeagues.includes(match.leagueId);
            const isSeasonSelected = selectAllSeasons || selectedSeasons.includes(match.seasonId);

            // Home ve Away filtreleme
            const isHomeMatch = showHomeMatches && match.homeId === matchTeamsDetail.homeId;
            const isAwayMatch = showAwayMatches && match.awayId === matchTeamsDetail.homeId;

            // Her iki checkbox seçiliyse maçın home/away durumu önemsenmez
            const isMatchIncluded = showHomeMatches && showAwayMatches
                ? true // Her iki checkbox seçiliyse bu filtreyi atla
                : isHomeMatch || isAwayMatch;

            // Tüm koşulları birleştir
            return isLeagueSelected && isSeasonSelected && isMatchIncluded;
        })
        .slice(0, selectedLimit);

    const date = value => {
        return dayjs(value).format('DD/MM/YYYY');
    };

    const matchScoreStyle = (match) => {
        if (!isPro && tabType !== 'Goals') {
            return;
        }

        if (matchTeamsDetail?.homeId === match.homeId) {
            if (match.hscore > match.ascore) {
                return 'mp-score-win'
            } else if (match.ascore > match.hscore) {
                return 'mp-score-lose'
            } else if (match.ascore === match.hscore) {
                return 'mp-score-draw'
            }
        } else if (matchTeamsDetail?.homeId === match.awayId) {
            if (match.hscore > match.ascore) {
                return 'mp-score-lose'
            } else if (match.ascore > match.hscore) {
                return 'mp-score-win'
            } else if (match.ascore === match.hscore) {
                return 'mp-score-draw'
            }
        }
    }

    const matchCornerCompareStyle = (match) => {
        if (!isPro && tabType !== 'Goals') {
            return;
        }

        if (matchTeamsDetail?.homeId === match.homeId) {
            if (match.hcorner > match.acorner) {
                return 'mp-score-win'
            } else if (match.acorner > match.hcorner) {
                return 'mp-score-lose'
            } else if (match.acorner === match.hcorner) {
                return 'mp-score-draw'
            }
        } else if (matchTeamsDetail?.homeId === match.awayId) {
            if (match.hcorner > match.acorner) {
                return 'mp-score-lose'
            } else if (match.acorner > match.hcorner) {
                return 'mp-score-win'
            } else if (match.acorner === match.hcorner) {
                return 'mp-score-draw'
            }
        }
    }

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className='match-plus-team-matches-general-container'>

            {/*JOIN PREMIUM POPUP*/}
            {
                isShownPopup &&
                <PopupPremium setIsShownPopup={setIsShownPopup}/>
            }

            <div className='general-cbox-section'>
                {/* Ligi Seçmek için Checkbox'lar */}
                <div className='mp-cbox-container'>
                    {/* Tümünü Seç Checkbox */}
                    <div className='all-cbox-container'>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectAllLeagues}
                                onChange={handleSelectAllLeagues}
                            />
                            <span className='all-cbox-name'>{i18String('All')}:</span>
                        </label>
                    </div>
                    <div className='mp-cbox-name-container'>
                        {homeTeamData?.leagues?.map((league) => (
                            <label key={league.id} className='mp-cbox-label'>
                                <input
                                    type="checkbox"
                                    value={league.id}
                                    checked={selectedLeagues.includes(league.leagueId)} // Her checkbox için doğru kontrol
                                    onChange={() => handleLeagueChange(league.leagueId)}
                                />
                                <span title={league.league} className='mp-cbox-name'>{league.league}</span>
                            </label>
                        ))}
                    </div>
                </div>

                {/* Tümünü Seç Checkbox (Sezonlar) */}
                <div className='mp-cbox-container'>
                    <div className='all-cbox-container'>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectAllSeasons}
                                onChange={handleSelectAllSeasons}
                            />
                            <span className='all-cbox-name'>{i18String('All')}:</span>
                        </label>
                    </div>
                    <div className='mp-cbox-season-name-container'>
                        {homeTeamData?.seasons.map((season) => (
                            <label key={season.id}>
                                <input
                                    type="checkbox"
                                    checked={selectedSeasons.includes(season.id)}
                                    onChange={() => handleSeasonChange(season.id)}
                                />
                                <span style={{fontSize: '10px'}}>{season.name}</span>
                            </label>
                        ))}
                    </div>
                </div>

                <div className='mp-cbox-container for-team-side-container'>
                    <div className='mp-team-side-general-container'>
                        <label className='team-side-label-container'>
                            <input
                                type="checkbox"
                                checked={showHomeMatches}
                                onChange={() => {
                                    if (!isPro) {
                                        setIsShownPopup(true);
                                        return;
                                    }
                                    setShowHomeMatches((prev) => !prev);
                                }}
                            />
                            {i18String('Home')}
                        </label>
                        <label className='team-side-label-container'>
                            <input
                                type="checkbox"
                                checked={showAwayMatches}
                                onChange={() => {
                                    if (!isPro) {
                                        setIsShownPopup(true);
                                        return;
                                    }
                                    setShowAwayMatches((prev) => !prev);
                                }}
                            />
                            {i18String('Away')}
                        </label>
                    </div>

                    {
                        tabType === 'Cards' &&
                        <div>
                            <select onChange={handleSelectChange} value={selectedOption} className='mp-match-count-select'>
                                <option value="cards">{i18String('Cards')}</option>
                                <option value="yellowCard">{i18String('Yellow Card')}</option>
                                <option value="redCard">{i18String('Red Card')}</option>
                            </select>
                        </div>
                    }

                    <div className='mp-match-count-option-container'>
                        <label htmlFor="matchLimit" className='match-count-text'>{i18String('Match count')}: </label>
                        <select id="matchLimit" value={selectedLimit} onChange={handleLimitChange} className='mp-match-count-select'>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className='mp-tables-container'>
                {
                    tabType === 'Goals' && filteredMatches?.length > 0 &&
                    <GoalsMPTable filteredMatches={filteredMatches} matchScoreStyle={matchScoreStyle} date={date}/>
                }
                {
                    tabType === 'Corners' && filteredMatches?.length > 0 &&
                    <CornersMPTable filteredMatches={filteredMatches} matchCornerCompareStyle={matchCornerCompareStyle} date={date}/>
                }
                {
                    tabType === 'Cards' && filteredMatches?.length > 0 &&
                    <CardsMPTable filteredMatches={filteredMatches} date={date} selectedOption={selectedOption}/>
                }
            </div>

        </div>
    );
}

export default HomeMatchPlus;
