import React, {useContext, useEffect, useRef, useState} from 'react';
import TopMenus from "./TopMenus";
import './Header.css'
import '../../App.css'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import img from '../../assets/icons/instagram.png'
import fl from '../../assets/flags/TR.png'
import fl1 from '../../assets/flags/EN.png'
import onLightMode from '../../assets/icons/onLightMode.png'
import offLightMode from '../../assets/icons/offLightMode.png'
import onDarkMode from '../../assets/icons/onDarkMode.png'
import offDarkMode from '../../assets/icons/offDarkMode.png'
import timeZoneIcon from '../../assets/icons/timeZone.png'
import darkModIconForWeb from '../../assets/icons/darkModeIconForWeb.png'
import darkModActiveIconForWeb from '../../assets/icons/darkModeActiveIconForWeb.png'
import lightModIconForWeb from '../../assets/icons/lightModeIconForWeb.png'
import lightModActiveIconForWeb from '../../assets/icons/lightModeActiveIconForWeb.png'
import userImg from '../../assets/icons/user.png'
import logo from '../../assets/icons/logo.png'
import Button from "@mui/material/Button";
import {AppContext} from "../../context/AppContext";
import {Autocomplete, FormControl, MenuItem, Popper, Select, Switch} from "@mui/material";
import {timeZoneLists} from "./TimeZones";
import TextField from "@mui/material/TextField";
import {languageLists} from './Languages';
import StatsScreenHeader from "./stats-screen-header/StatsScreenHeader";
import MobileHamburgerMenu from "./mobile-hamburger-menu/MobileHamburgerMenu";
import WarningPopup from "../warning-popup/WarningPopup";

function Header(props) {
    const {
        logOut,
        setLanguage,
        lang,
        i18String,
        siteTheme,
        timeZone,
        setTimeZoneForGeneral,
        setDarkTheme,
        setLightTheme,
        isPro,
        geo
    } = useContext(AppContext);

    const userToken = localStorage.getItem('user-token');
    const username = localStorage.getItem('username');
    const navigate = useNavigate();
    const userDetail = localStorage.getItem('user');

    const user = JSON.parse(userDetail);

    const currentRoutes = useLocation();

    const pathName = currentRoutes.pathname;
    const joinPremiumButton = () => {
        if (pathName !== '/sign-in' && pathName !== '/sign-up') {
            if (!user) {
                return (
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%', background: 'red'}}>
                        <button className='fixed-join-premium-container'
                                onClick={() => toPremium()}
                        >
                            <span className='fixed-join-premium-text'>Join to Stats365 Premium</span>
                        </button>
                    </div>
                )
            } else if (!isPro) {
                return (
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%', background: 'red'}}>
                        <button className='fixed-join-premium-container'
                                onClick={() => toPremium()}
                        >
                            <span className='fixed-join-premium-text'>Join to Stats365 Premium</span>
                        </button>
                    </div>
                )
            }
        }
    }

    const toPremium = () => {
        navigate('/join-premium');
        //scrollToRef();
    }

    const location = useLocation();
    const [count, setCount] = useState(0);
    const isLmtOn = pathName.includes('/fv-lmt-v3');

    useEffect(() => {
        // execute on location change
        setCount(count + 1);
    }, [location]);

    const activeDarkThemeButtonStyle = () => {
        if (siteTheme === 'dark') {
            return {
                background: '#24262b',
                borderRadius: '4px 0  0 4px',
                color: '#ffffff',
                padding: '3px',
                minWidth: '35px'
            }
        } else {
            return {
                background: '#484a50',
                borderRadius: '4px 0  0 4px',
                color: '#ffffff',
                padding: '3px',
                minWidth: '35px'
            }
        }
    }

    const activeLightThemeButtonStyle = () => {
        if (siteTheme === 'light') {
            return {
                background: '#24262b',
                borderRadius: '0 4px 4px 0',
                color: '#ffffff',
                padding: '3px',
                minWidth: '35px'
            }
        } else {
            return {
                background: '#484a50',
                borderRadius: '0 4px 4px 0',
                color: '#ffffff',
                padding: '3px',
                minWidth: '35px'
            }
        }
    }

    const CustomOption = ({innerProps, label, data}) => (
        <div {...innerProps}>
            <img
                src={require(`${data.imgSource}`)}
                alt={data.text}
                style={{marginRight: '8px', width: '20px', height: '20px'}}
            />
            {label.text}
        </div>
    );


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleTimeZoneSelect = (event, value) => {
        if (timeZoneLists.some((item) => item.value === value.value)) {
            setTimeZoneForGeneral(value.value);
            setSearchTerm('');
        } else {
            setTimeZoneForGeneral(timeZone);
            setSearchTerm('');
        }
    };

    const filteredTimeZones = timeZoneLists.filter((item) =>
        item.text.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {/*WARING POPUP*/}
            {
                geo && (geo.countryCode === 'TR') && (pathName === '/') && <WarningPopup/>
            }
            {
                pathName !== '/landing' && pathName !== '/android' &&
                <div className={isLmtOn ? 'isLmtOn' : 'isLmtOff'}>
                    {
                        location.pathname === '/join-premium' &&
                        <div className="join-premium-header-container">
                            <div>
                                <Link to={'/'} className="site-logo-text">
                                    <img className={'logo'} src={logo} alt="user"/>
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        location.pathname !== `/join-premium` &&
                        <div>
                            {joinPremiumButton()}

                            <div className="mid-header-section">
                                {/*MOBILE GENERAL HEADER*/}
                                {
                                    location.pathname !== '/' &&
                                    <div className='mobile-header-section'>
                                        <MobileHamburgerMenu />
                                        <div className='const-width'>
                                            <div className='logo-container'>
                                                <Link to={'/'} className="site-logo-text">
                                                    <img className={'logo'} src={logo} alt="user"/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    location.pathname === '/' &&
                                    <div className='mobile-header-section'>
                                        <StatsScreenHeader/>

                                    </div>
                                }

                                {/*WEB GENERAL HEADER*/}
                                <div className="const-width web-header-section">
                                    <div className="logo-container">
                                        <Link to={'/'} className="site-logo-text">
                                            <img className={'logo'} src={logo} alt="user"/>
                                        </Link>

                                        {/*<Link to={'/tv'} className='tv-icon-container'>
                                    <img style={{
                                        background: 'white',
                                        width: '25px',
                                        height: '20px',
                                        padding: '3px',
                                        borderRadius: '5px'
                                    }} src={tvIcon} alt="user"/>
                                </Link>*/}

                                        <div className='flags-for-language-section'>
                                            <div className='switch-theme-and-time-zone-section'>
                                                <div className='select-time-zone-container'>
                                                    <FormControl fullWidth>
                                                        {/*<Select
                                                    id="stat"
                                                    value={timeZone}
                                                    onChange={(event) => handleTimeZoneSelect(event.target.value)}
                                                    defaultValue={timeZone}
                                                >
                                                    <div className='search-time-zone-input-container'>
                                                        <Input
                                                            style={{width: '100%', margin: '10px 0', padding: 0}}
                                                            autoFocus
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={handleSearchInputChange}
                                                        />
                                                    </div>
                                                    {
                                                        filteredTimeZones.length === 0 ?
                                                            <MenuItem disabled onClick={() => {}}>No data</MenuItem>
                                                            :
                                                            filteredTimeZones.map((item, index) => (
                                                                <MenuItem color='red' key={index} value={item.value}>
                                                                    {item.text}
                                                                </MenuItem>
                                                            ))
                                                    }
                                                </Select>*/}
                                                        <div className='timezone-autocomplete-container'>
                                                            <Autocomplete
                                                                style={{width: 170}}
                                                                freeSolo
                                                                id="free-solo-2-demo"
                                                                disableClearable
                                                                defaultValue={timeZone}
                                                                value={timeZone}
                                                                options={filteredTimeZones.map((option) => ({
                                                                    label: option.text,
                                                                    value: option.value,
                                                                }))}
                                                                PopperComponent={(props) => (
                                                                    <Popper {...props} style={{width: '350px'}}>
                                                                        {props.children}
                                                                    </Popper>
                                                                )}
                                                                renderOption={(props, option) => (
                                                                    <MenuItem {...props} style={{fontSize: '14px'}}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                )}
                                                                onChange={(event, value) => handleTimeZoneSelect(event, value)}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="" InputProps={{
                                                                        ...params.InputProps,
                                                                        type: 'search'
                                                                    }}/>
                                                                )}
                                                            />
                                                        </div>
                                                    </FormControl>

                                                    <img className='timezone-icon' src={timeZoneIcon} alt={'theme'}/>
                                                </div>

                                                <div className='pipe-for-headers-container'></div>

                                                {/*<div className='change-theme-buttons-for-web-container'>
                                            <Button
                                                onClick={() => setDarkTheme()}
                                                style={activeDarkThemeButtonStyle()}
                                            >
                                                {
                                                    siteTheme === 'dark' ?
                                                        <img className='theme-mode-icons' src={darkModActiveIconForWeb}
                                                             alt={'theme'}/>
                                                        :
                                                        <img className='theme-mode-icons' src={darkModIconForWeb}
                                                             alt={'theme'}/>
                                                }
                                            </Button>
                                            <Button
                                                onClick={() => setLightTheme()}
                                                style={activeLightThemeButtonStyle()}
                                            >
                                                {
                                                    siteTheme === 'light' ?
                                                        <img className='theme-mode-icons' src={lightModActiveIconForWeb}
                                                             alt='Light Mode Active'/>
                                                        :
                                                        <img className='theme-mode-icons' src={lightModIconForWeb}
                                                             alt='Light Mode'/>
                                                }
                                            </Button>
                                        </div>*/}
                                            </div>

                                            <div className='pipe-for-headers-container'></div>

                                            <div className='language-flags-container'>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id='language-select'
                                                        value={lang}
                                                        defaultValue={lang}
                                                        onChange={(event) => setLanguage(event.target.value)}
                                                    >
                                                        {languageLists?.map((item, index) => (
                                                            <MenuItem
                                                                className='language-flags-and-country-container'
                                                                key={index}
                                                                value={item.value}
                                                            >
                                                                <img className='language-flags' src={item.imgSource}
                                                                     alt={item.imgSource}/>
                                                                <span className='language-text'>{item.text}</span>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {
                                            userToken ?
                                                <div className={'exit-button-container'}>
                                                    <div className={'user-name-container'}>
                                                        <Link className={'username-text-web'}
                                                              to={`/account-detail/${username}`}>{username}</Link>
                                                    </div>
                                                    <Button variant="outlined" onClick={logOut}>
                                                        <span>{i18String('Logout')}</span>
                                                    </Button>
                                                </div>
                                                :
                                                <div className={'sign-in-sign-up-button-section'}>
                                                    <div className={'sign-in-button-container'}>
                                                        <Link to={'sign-in'}
                                                              className={'sign-in-text'}>{i18String('Login')}</Link>
                                                    </div>

                                                    <div className={'sign-up-button-container'}>
                                                        <Link to={'sign-up'}
                                                              className={'sign-up-text'}>{i18String('Register')}</Link>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="top-navigation-bar">
                                <div className="const-width">
                                    <TopMenus></TopMenus>
                                </div>
                            </div>
                            <Outlet/>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default Header;
