import React, {useEffect, useState} from 'react';
import {fetchH2h, fetchH2hAllStats} from "../../../api";
import dayjs from "dayjs";
import winIcon from '../../../assets/icons/compare-win.png'
import loseIcon from '../../../assets/icons/compare-lose.png'
import drawIcon from '../../../assets/icons/compare-draw.png'
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";
import Box from "@mui/material/Box";
import {styled} from "@mui/material";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import cp1 from '../../../assets/icons/comparison1.png'
import cp2 from '../../../assets/icons/comparison2.png'
import cp3 from '../../../assets/icons/comparison3.png'
import cornerFlag from "../../../assets/icons/corner-flag.png";
import {saveMatchToLocalStorage} from "../../local-storage/ManageLocalStorage";

function Compare(props) {
    const {matchTeamsDetail, matchId, lang, i18String} = props;

    const [h2hData, setH2hData] = useState(null);
    const [h2hAllStatsData, setH2hAllStatsData] = useState(null);

    useEffect(() => {
        fetchH2h(matchId).then(data => {
            setH2hData(data.data.data);
        })

        fetchH2hAllStats(matchId).then(data => {
            setH2hAllStatsData(data.data.data);
        })
    }, [])

    const date = value => {
        return dayjs(value).format('DD.MM.YYYY');
    };

    const dateForH2h = value => {
        return dayjs(value).format('DD.MM.YYYY');
    };

    const matchResultForTeam = (match, titleId) => {
        if (match.homeId === titleId) {
            if (match.hscore > match.ascore) {
                return winIcon
            } else if (match.hscore < match.ascore) {
                return loseIcon
            } else {
                return drawIcon
            }
        } else {
            if (match.hscore < match.ascore) {
                return winIcon
            } else if (match.hscore > match.ascore) {
                return loseIcon
            } else {
                return drawIcon
            }
        }
    };

    const h2hMatchesTeamStyle = (data) => {
        if (matchTeamsDetail?.homeId === data.homeId) {
            if (data.hscore > data.ascore) {
                return 'match-detail-h2h-home-win'
            } else if (data.ascore > data.hscore) {
                return 'match-detail-h2h-normal-team-style'
            } else if (data.ascore === data.hscore) {
                return 'match-detail-h2h-draw'
            }
        } else if (matchTeamsDetail?.awayId === data.homeId) {
            if (data.hscore > data.ascore) {
                return 'match-detail-h2h-away-win'
            } else if (data.ascore > data.hscore) {
                return 'match-detail-h2h-normal-team-style'
            } else if (data.ascore === data.hscore) {
                return 'match-detail-h2h-draw'
            }
        }
    }
    const h2hMatchesScoreStyle = (data) => {
        if (matchTeamsDetail?.homeId === data.homeId) {
            if (data.hscore > data.ascore) {
                return 'team-win-score'
            } else if (data.ascore > data.hscore) {
                return 'team-lost-score'
            } else if (data.ascore === data.hscore) {
                return 'team-normal-score'
            }
        } else if (matchTeamsDetail?.homeId === data.awayId) {
            if (data.hscore > data.ascore) {
                return 'team-lost-score'
            } else if (data.ascore > data.hscore) {
                return 'team-win-score'
            } else if (data.ascore === data.hscore) {
                return 'team-normal-score'
            }
        }
    }
    const h2hMatchesAwayScoreStyle = (data) => {
        if (matchTeamsDetail?.awayId === data.homeId) {
            if (data.hscore > data.ascore) {
                return 'team-win-score'
            } else if (data.ascore > data.hscore) {
                return 'team-lost-score'
            } else if (data.ascore === data.hscore) {
                return 'team-normal-score'
            }
        } else if (matchTeamsDetail?.awayId === data.awayId) {
            if (data.hscore > data.ascore) {
                return 'team-lost-score'
            } else if (data.ascore > data.hscore) {
                return 'team-win-score'
            } else if (data.ascore === data.hscore) {
                return 'team-normal-score'
            }
        }
    }

    const h2hMatchesTeamStyleAway = (data) => {
        if (matchTeamsDetail?.awayId === data.awayId) {
            if (data.ascore > data.hscore) {
                return 'match-detail-h2h-away-win'
            } else if (data.hscore > data.ascore) {
                return 'match-detail-h2h-normal-team-style'
            } else if (data.ascore === data.hscore) {
                return 'match-detail-h2h-draw'
            }
        } else if (matchTeamsDetail?.awayId === data.homeId) {
            if (data.ascore > data.hscore) {
                return 'match-detail-h2h-home-win'
            } else if (data.ascore > data.hscore) {
                return 'match-detail-h2h-normal-team-style'
            } else if (data.ascore === data.hscore) {
                return 'match-detail-h2h-draw'
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 1,
        width: 50,
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 1,
        },
    }));

    const comparisonCalc = (perc) => {
        if (perc === 0) {
            return {
                flex: 0.009
            }
        } else {
            return {
                width: perc * 1.5
            }
        }
    }

    const calculate25Over = (hscore, ascore) => {
        if (hscore + ascore >= 3) {
            return 'Ü'
        } else {
            return 'A'
        }
    }

    const calculate25OverStyle = (hscore, ascore) => {
        if (hscore + ascore >= 3) {
            return {
                fontWeight: 'bold',
                color: '#5bbf5c'
            }
        } else {
            return {
                fontWeight: 'bold',
                color: '#d44c4c'
            }
        }
    }

    const calculateKG = (hscore, ascore) => {
        if (hscore > 0 && ascore > 0) {
            return 'KG'
        } else {
            return '-'
        }
    }

    return (
        <div>
            <div className='h2h-general-section-container'>
                {/*PREVIOUS MATCHES*/}
                <div className='h2h-table-container'>

                    {
                        h2hAllStatsData &&
                        <div>
                            <div className='h2h-table-titles'>{i18String('Previous Matches')}</div>

                            <div className='prev-matches-tables-section'>
                                {/*ALL MATCHES*/}
                                <div className='prev-matches-tables-container'>
                                    <div className='match-detail-all-matches-table'>
                                        {i18String('All Matches')}
                                    </div>
                                    <div className='match-detail-percent-row'>
                                        <span className='all-matches-side comp1-team'>{matchTeamsDetail?.home}</span>
                                        {/*<div className='all-matches-side-percent'>{h2hAllStatsData?.homeWinsPercent}</div>*/}
                                        <div className='comparison-general-container'>
                                            <div style={comparisonCalc(h2hAllStatsData?.all?.homeWinsPercent)}
                                                 className='comp1'></div>
                                        </div>
                                        <div>{h2hAllStatsData?.all?.homeWins}</div>
                                    </div>
                                    <div className='match-detail-percent-row'>
                                        <span className='all-matches-side comp3-team'>Beraberlik</span>
                                        {/*<div className='all-matches-side-percent'>{h2hAllStatsData?.drawsPercent}</div>*/}
                                        <div className='comparison-general-container'>
                                            <div style={comparisonCalc(h2hAllStatsData?.all?.drawsPercent)}
                                                 className='comp2'></div>
                                        </div>
                                        <div>{h2hAllStatsData?.all?.draws}</div>
                                    </div>
                                    <div className='match-detail-percent-row'>
                                        <span className='all-matches-side comp2-team'>{matchTeamsDetail?.away}</span>
                                        {/*<div className='all-matches-side-percent'>{h2hAllStatsData?.awayWinsPercent}</div>*/}
                                        <div className='comparison-general-container'>
                                            <div style={comparisonCalc(h2hAllStatsData?.all?.awayWinsPercent)}
                                                 className='comp3'></div>
                                        </div>
                                        <div>{h2hAllStatsData?.all?.awayWins}</div>
                                    </div>
                                </div>

                                {/*HOME MATCHES*/}
                                <div className='prev-matches-tables-container'>
                                    <div className='match-detail-all-matches-table'>
                                        {matchTeamsDetail.home} Evindeki Maçlar
                                    </div>
                                    <div className='match-detail-percent-row'>
                                        <span className='all-matches-side comp1-team'>{matchTeamsDetail.home}</span>
                                        <div className='comparison-general-container'>
                                            <div style={comparisonCalc(h2hAllStatsData?.home?.homeWinsPercent)}
                                                 className='comp1'></div>
                                        </div>
                                        <div>{h2hAllStatsData?.home?.homeWins}</div>
                                    </div>
                                    <div className='match-detail-percent-row'>
                                        <span className='all-matches-side comp3-team'>Beraberlik</span>
                                        <div className='comparison-general-container'>
                                            <div style={comparisonCalc(h2hAllStatsData?.home?.drawsPercent)}
                                                 className='comp2'></div>
                                        </div>
                                        <div>{h2hAllStatsData?.home?.draws}</div>
                                    </div>
                                    <div className='match-detail-percent-row'>
                                        <span className='all-matches-side comp2-team'>{matchTeamsDetail.away}</span>
                                        <div className='comparison-general-container'>
                                            <div style={comparisonCalc(h2hAllStatsData?.home?.awayWinsPercent)}
                                                 className='comp3'></div>
                                        </div>
                                        <div>{h2hAllStatsData?.home?.awayWins}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        h2hData && h2hData?.h2hMatches &&
                        <div className='match-detail-h2h-table-header-container'>
                            <div>Tarih</div>
                            <div></div>
                            <div>{i18String('Match')}</div>
                            <div></div>
                            <div>{i18String('HT')}</div>
                            <div title={i18String('Corner')} style={{display: 'flex'}}>
                                <img src={cornerFlag} alt="Corner" className='compare-corner-icon' />
                            </div>
                            <div>2.5AÜ</div>
                            <div>KG</div>
                        </div>
                    }
                    {
                        h2hData && h2hData?.h2hMatches &&
                        h2hData.h2hMatches?.map((data, index) => {
                            return (
                                <div
                                    className={`only-h2h-row-section ${index % 2 === 0 ? 'stripped-row' : 'normal-row'}`}>
                                    <div>
                                        <span
                                            className='match-detail-compare-date'>{dateForH2h(data.dateTimeUtc)}</span>
                                    </div>

                                    <div className="card-container only-web">
                                        {data.hred > 0 &&
                                            <div className="match-card red-card">{data.hred}</div>}
                                    </div>

                                    <div className="card-container only-web">
                                        {data.hyellow > 0 &&
                                            <div className="match-card yellow-card">{data.hyellow}</div>}
                                    </div>

                                    <div className='h2h-home-team-container'>
                                        <a target={"_blank"} href={`/team-detail/${data.homeId}`} className={h2hMatchesTeamStyle(data)}>
                                            {data.home}
                                        </a>
                                    </div>

                                    <div
                                        onClick={() => saveMatchToLocalStorage(`teams-state:${data.id}`, {
                                            matchId: data.id,
                                            home: data.home,
                                            homeId: data.homeId,
                                            away: data.away,
                                            awayId: data.awayId,
                                            time: data.dateTimeUtc,
                                            seasonId: data.seasonId
                                        })}
                                        className='h2h-score-container'>
                                        <div className='h2h-score-web'>
                                            <span
                                                className='match-detail-compare-score'>{data.hscore} - {data.ascore}
                                            </span>
                                        </div>
                                        <div className='h2h-score-mobile team-normal-score'>
                                            <span
                                                className='match-detail-compare-score'>{data.hscore}:{data.ascore}
                                            </span>
                                        </div>
                                    </div>

                                    <div className='h2h-away-team-container'>
                                        <a target={"_blank"} href={`/team-detail/${data.awayId}`} className={h2hMatchesTeamStyleAway(data)}>
                                            {data.away}
                                        </a>
                                    </div>

                                    <div className="card-container only-web">
                                        {data.ayellow > 0 &&
                                            <div className="match-card yellow-card">{data.ayellow}</div>}
                                    </div>

                                    <div className="card-container only-web">
                                        {data.ared > 0 &&
                                            <div className="match-card red-card">{data.ared}</div>}
                                    </div>

                                    <div className='h2h-p1-score-between only-web'>
                                        <span
                                            className='match-detail-compare-first-period-score'>{data.hp1} - {data.ap1}</span>
                                    </div>

                                    <div className="compare-1half only-web">{data.hcorner}-{data.acorner}</div>

                                    <div className='compare-25over-and-kg-container only-web'>
                                        <span style={calculate25OverStyle(data.hscore, data.ascore)}>{calculate25Over(data.hscore, data.ascore)}</span>
                                    </div>

                                    <div className='compare-25over-and-kg-container only-web'>
                                        <span style={{color: '#444444'}}>{calculateKG(data.hscore, data.ascore)}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/*HOME MATCHES*/}
                <div className='match-detail-compare-form-tables-section'>
                    <div className='match-detail-compare-form-table'>
                        <div className='match-detail-compare-form-team-row-container'>
                            <div className='match-detail-logo-and-team-container'>
                                <img className="match-detail-teams-form-logo"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.homeId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt=""/>
                                <div className='form-table-titles'>{matchTeamsDetail?.home} - {i18String('Form')}</div>
                            </div>

                            <div className='only-web'>{i18String('Match')}</div>

                            <div className='only-web'></div>

                            <div className='only-web'>{i18String('HT')}</div>
                            <div className='only-web' title={i18String('Corner')} style={{display: 'flex'}}>
                                <img src={cornerFlag} alt="Corner" className='compare-corner-icon' />
                            </div>
                            <div className='only-web'>2.5AÜ</div>
                            <div className='only-web'>KG</div>
                        </div>
                        {
                            h2hData &&
                            h2hData.homeMatches?.map((data, index) => {
                                return (
                                    <div className={`h2h-row-section ${index % 2 === 0 ? 'stripped-row' : 'normal-row'}`}>
                                        <div>
                                            <span className='match-detail-compare-date'>{date(data.dateTimeUtc)}</span>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hred > 0 &&
                                                <div className="match-card red-card">{data.hred}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hyellow > 0 &&
                                                <div className="match-card yellow-card">{data.hyellow}</div>}
                                        </div>

                                        <div className='h2h-home-team-container'>
                                        <a  target={"_blank"} href={`/team-detail/${data.homeId}`}
                                            className={matchTeamsDetail?.homeId === data.homeId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                            style={{fontWeight: data.hscore > data.ascore ? '600' : '500'}}
                                        >
                                            {data.home}
                                        </a>
                                        </div>

                                        <div
                                            onClick={() => saveMatchToLocalStorage(`teams-state:${data.id}`, {
                                                matchId: data.id,
                                                home: data.home,
                                                homeId: data.homeId,
                                                away: data.away,
                                                awayId: data.awayId,
                                                time: data.dateTimeUtc,
                                                seasonId: data.seasonId
                                            })}
                                            className='h2h-score-container'>
                                            <div className='h2h-score-web'>
                                                <span
                                                className='match-detail-compare-score'>{data.hscore} - {data.ascore}</span>
                                            </div>
                                            <div className='h2h-score-mobile'>
                                                <div className={h2hMatchesScoreStyle(data)}>
                                                    <span
                                                        className='match-detail-compare-score'>{data.hscore}:{data.ascore}
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='h2h-away-team-container'>
                                            <a  target={"_blank"} href={`/team-detail/${data.awayId}`}
                                                className={matchTeamsDetail?.homeId === data.awayId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                                style={{fontWeight: data.ascore > data.hscore ? '600' : '500'}}
                                            >
                                                {data.away}
                                            </a>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ayellow > 0 &&
                                                <div className="match-card yellow-card">{data.ayellow}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ared > 0 &&
                                                <div className="match-card red-card">{data.ared}</div>}
                                        </div>

                                        <div className='h2h-p1-score only-web'>
                                            <span className='match-detail-compare-first-period-score'>{data.hp1} - {data.ap1}</span>
                                        </div>

                                        <span title={i18String('Corner')} className="card-container only-web compare-corner-container">
                                            <div className="compare-1half">{data.hcorner}-{data.acorner}</div>
                                        </span>

                                        <div className='compare-25over-and-kg-container'>
                                            <span title={'Alt/Üst'} style={calculate25OverStyle(data.hscore, data.ascore)}>{calculate25Over(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div title={'Karşılıklı Gol'} className='compare-25over-and-kg-container'>
                                            <span style={{color: '#444444'}}>{calculateKG(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div className='match-detail-compare-status-icon-container'>
                                            <img src={matchResultForTeam(data, matchTeamsDetail?.homeId)} alt=""/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/*AWAY MATCHES*/}
                    <div className='match-detail-compare-form-table'>
                        <div className='match-detail-compare-form-team-row-container'>
                            <div className='match-detail-logo-and-team-container'>
                                <img className="match-detail-teams-form-logo"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.awayId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt=""/>
                                <div className='form-table-titles'>{matchTeamsDetail?.away} - {i18String('Form')}</div>
                            </div>

                            <div className='only-web'>{i18String('Match')}</div>

                            <div className='only-web'></div>

                            <div className='only-web'>{i18String('HT')}</div>
                            <div className='only-web' title={i18String('Corner')} style={{display: 'flex'}}>
                                <img src={cornerFlag} alt="Corner" className='compare-corner-icon' />
                            </div>
                            <div className='only-web'>2.5AÜ</div>
                            <div className='only-web'>KG</div>
                        </div>
                        {
                            h2hData &&
                            h2hData.awayMatches?.map((data, index) => {
                                return (
                                    <div className={`h2h-row-section ${index % 2 === 0 ? 'stripped-row' : 'normal-row'}`}>
                                        <div>
                                            <span className='match-detail-compare-date'>{date(data.dateTimeUtc)}</span>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hred > 0 &&
                                                <div className="match-card red-card">{data.hred}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hyellow > 0 &&
                                                <div className="match-card yellow-card">{data.hyellow}</div>}
                                        </div>

                                        <div className='h2h-home-team-container'>
                                        <a  target={"_blank"} href={`/team-detail/${data.homeId}`}
                                            className={matchTeamsDetail?.awayId === data.homeId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                            style={{fontWeight: data.hscore > data.ascore ? '600' : '500'}}
                                        >{data.home}
                                        </a>
                                        </div>

                                        <div
                                            onClick={() => saveMatchToLocalStorage(`teams-state:${data.id}`, {
                                                matchId: data.id,
                                                home: data.home,
                                                homeId: data.homeId,
                                                away: data.away,
                                                awayId: data.awayId,
                                                time: data.dateTimeUtc,
                                                seasonId: data.seasonId
                                            })}
                                            className='h2h-score-container'>
                                            <div className='h2h-score-web'>
                                                <span
                                                    className='match-detail-compare-score'>{data.hscore} - {data.ascore}</span>
                                            </div>
                                            <div className='h2h-score-mobile'>
                                                <div className={h2hMatchesAwayScoreStyle(data)}>
                                                    <span
                                                        className='match-detail-compare-score'>{data.hscore}:{data.ascore}
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='h2h-away-team-container'>
                                            <a target={"_blank"} href={`/team-detail/${data.awayId}`}
                                               className={matchTeamsDetail?.awayId === data.awayId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                               style={{fontWeight: data.ascore > data.hscore ? '600' : '500'}}
                                            >{data.away}</a>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ayellow > 0 &&
                                                <div className="match-card yellow-card">{data.ayellow}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ared > 0 &&
                                                <div className="match-card red-card">{data.ared}</div>}
                                        </div>

                                        <div className='h2h-p1-score only-web'>
                                            <span className='match-detail-compare-first-period-score'>{data.hp1} - {data.ap1}</span>
                                        </div>

                                        <span title={i18String('Corner')} className="card-container only-web compare-corner-container">
                                            <div className="compare-1half">{data.hcorner}-{data.acorner}</div>
                                        </span>

                                        <div className='compare-25over-and-kg-container'>
                                            <span title={'Alt/Üst'} style={calculate25OverStyle(data.hscore, data.ascore)}>{calculate25Over(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div title={'Karşılıklı Gol'} className='compare-25over-and-kg-container'>
                                            <span style={{color: '#444444'}}>{calculateKG(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div className='match-detail-compare-status-icon-container'>
                                            <img src={matchResultForTeam(data, matchTeamsDetail?.awayId)} alt=""/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {/*HHOME MATCHES*/}
                <div className='match-detail-compare-form-tables-section'>
                    <div className='match-detail-compare-form-table'>
                        <div className='match-detail-compare-form-team-row-container'>
                            <div className='match-detail-logo-and-team-container'>
                                <img className="match-detail-teams-form-logo"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.homeId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt=""/>
                                <div className='form-table-titles'>{matchTeamsDetail?.home} - {i18String('Home Form')}</div>
                            </div>

                            <div className='only-web'>{i18String('Match')}</div>

                            <div className='only-web'></div>

                            <div className='only-web'>{i18String('HT')}</div>
                            <div className='only-web' title={i18String('Corner')} style={{display: 'flex'}}>
                                <img src={cornerFlag} alt="Corner" className='compare-corner-icon' />
                            </div>
                            <div className='only-web'>2.5AÜ</div>
                            <div className='only-web'>KG</div>
                        </div>
                        {
                            h2hData &&
                            h2hData.homeHMatches?.map((data, index) => {
                                return (
                                    <div className={`h2h-row-section ${index % 2 === 0 ? 'stripped-row' : 'normal-row'}`}>
                                        <div>
                                            <span className='match-detail-compare-date'>{date(data.dateTimeUtc)}</span>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hred > 0 &&
                                                <div className="match-card red-card">{data.hred}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hyellow > 0 &&
                                                <div className="match-card yellow-card">{data.hyellow}</div>}
                                        </div>

                                        <div className='h2h-home-team-container'>
                                        <a target={"_blank"} href={`/team-detail/${data.homeId}`}
                                           className={matchTeamsDetail?.homeId === data.homeId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                           style={{fontWeight: data.hscore > data.ascore ? '600' : '500'}}
                                        >{data.home}</a>
                                        </div>

                                        <div
                                            onClick={() => saveMatchToLocalStorage(`teams-state:${data.id}`, {
                                                matchId: data.id,
                                                home: data.home,
                                                homeId: data.homeId,
                                                away: data.away,
                                                awayId: data.awayId,
                                                time: data.dateTimeUtc,
                                                seasonId: data.seasonId
                                            })}
                                            className='h2h-score-container'>
                                            <div className='h2h-score-web'>
                                                <span
                                                    className='match-detail-compare-score'>{data.hscore} - {data.ascore}</span>
                                            </div>
                                            <div className='h2h-score-mobile'>
                                                <div className={h2hMatchesScoreStyle(data)}>
                                                    <span
                                                        className='match-detail-compare-score'>{data.hscore}:{data.ascore}
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='h2h-away-team-container'>
                                            <a  target={"_blank"} href={`/team-detail/${data.awayId}`}
                                                className={matchTeamsDetail?.homeId === data.awayId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                                style={{fontWeight: data.ascore > data.hscore ? '600' : '500'}}
                                            >{data.away}</a>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ayellow > 0 &&
                                                <div className="match-card yellow-card">{data.ayellow}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ared > 0 &&
                                                <div className="match-card red-card">{data.ared}</div>}
                                        </div>

                                        <div className='h2h-p1-score only-web'>
                                            <span className='match-detail-compare-first-period-score'>{data.hp1} - {data.ap1}</span>
                                        </div>

                                        <span title={i18String('Corner')} className="card-container only-web compare-corner-container">
                                            <div className="compare-1half">{data.hcorner}-{data.acorner}</div>
                                        </span>

                                        <div className='compare-25over-and-kg-container'>
                                            <span title={'Alt/Üst'} style={calculate25OverStyle(data.hscore, data.ascore)}>{calculate25Over(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div title={'Karşılıklı Gol'} className='compare-25over-and-kg-container'>
                                            <span style={{color: '#444444'}}>{calculateKG(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div className='match-detail-compare-status-icon-container'>
                                            <img src={matchResultForTeam(data, matchTeamsDetail?.homeId)} alt=""/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/*AAWAY MATCHES*/}
                    <div className='match-detail-compare-form-table'>
                        <div className='match-detail-compare-form-team-row-container'>
                            <div className='match-detail-logo-and-team-container'>
                                <img className="match-detail-teams-form-logo"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.awayId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt=""/>
                                <div className='form-table-titles'>{matchTeamsDetail?.away} - {i18String('Away Form')}</div>
                            </div>

                            <div className='only-web'>{i18String('Match')}</div>

                            <div className='only-web'></div>

                            <div className='only-web'>{i18String('HT')}</div>
                            <div className='only-web' title={i18String('Corner')} style={{display: 'flex'}}>
                                <img src={cornerFlag} alt="Corner" className='compare-corner-icon' />
                            </div>
                            <div className='only-web'>2.5AÜ</div>
                            <div className='only-web'>KG</div>
                        </div>
                        {
                            h2hData &&
                            h2hData.awayAMatches?.map((data, index) => {
                                return (
                                    <div className='h2h-row-section'
                                         style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}>
                                        <div>
                                            <span className='match-detail-compare-date'>{date(data.dateTimeUtc)}</span>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hred > 0 &&
                                                <div className="match-card red-card">{data.hred}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.hyellow > 0 &&
                                                <div className="match-card yellow-card">{data.hyellow}</div>}
                                        </div>

                                        <div className='h2h-home-team-container'>
                                        <a  target={"_blank"} href={`/team-detail/${data.homeId}`}
                                            className={matchTeamsDetail?.awayId === data.homeId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                            style={{fontWeight: data.hscore > data.ascore ? '600' : '500'}}
                                        >{data.home}</a>
                                        </div>

                                        <div
                                            onClick={() => saveMatchToLocalStorage(`teams-state:${data.id}`, {
                                                matchId: data.id,
                                                home: data.home,
                                                homeId: data.homeId,
                                                away: data.away,
                                                awayId: data.awayId,
                                                time: data.dateTimeUtc,
                                                seasonId: data.seasonId
                                            })}
                                            className='h2h-score-container'>
                                            <div className='h2h-score-web'>
                                                <span
                                                    className='match-detail-compare-score'>{data.hscore} - {data.ascore}</span>
                                            </div>
                                            <div className='h2h-score-mobile'>
                                                <div className={h2hMatchesAwayScoreStyle(data)}>
                                                    <span
                                                        className='match-detail-compare-score'>{data.hscore}:{data.ascore}
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='h2h-away-team-container'>
                                            <a  target={"_blank"} href={`/team-detail/${data.awayId}`}
                                                className={matchTeamsDetail?.awayId === data.awayId ? 'h2h-select-team' : 'h2h-unselect-team'}
                                                style={{fontWeight: data.ascore > data.hscore ? '600' : '500'}}
                                            >{data.away}</a>
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ayellow > 0 &&
                                                <div className="match-card yellow-card">{data.ayellow}</div>}
                                        </div>

                                        <div className="card-container only-web">
                                            {data.ared > 0 &&
                                                <div className="match-card red-card">{data.ared}</div>}
                                        </div>

                                        <div className='h2h-p1-score only-web'>
                                            <span className='match-detail-compare-first-period-score'>{data.hp1} - {data.ap1}</span>
                                        </div>

                                        <span title={i18String('Corner')} className="card-container only-web compare-corner-container">
                                            <div className="compare-1half">{data.hcorner}-{data.acorner}</div>
                                        </span>

                                        <div className='compare-25over-and-kg-container'>
                                            <span title={'Alt/Üst'} style={calculate25OverStyle(data.hscore, data.ascore)}>{calculate25Over(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div title={'Karşılıklı Gol'} className='compare-25over-and-kg-container'>
                                            <span style={{color: '#444444'}}>{calculateKG(data.hscore, data.ascore)}</span>
                                        </div>

                                        <div className='match-detail-compare-status-icon-container'>
                                            <img src={matchResultForTeam(data, matchTeamsDetail?.awayId)} alt=""/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Compare;
