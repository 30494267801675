import React, {useContext} from "react";
import {AppContext} from "../../../context/AppContext";
import './Lineups.css';

function LineupCard(props) {
    const {lang} = useContext(AppContext);
    const {data, teamName, teamId} = props;

    return (
        <div className='lineupsGeneralCardContainer'>
            <div
                onClick={() => {
                    window.open(`/team-detail/${teamId}`)
                }}
                className='teamLogoAndName'>
                <img
                    src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${teamId}.png`}
                    alt={teamName}
                    style={{width: '25px', height: '25px'}}
                />
                <span className='lineupTeamName'>{teamName}</span>
            </div>

            <div className='lineupPlayerNameGeneralContainer'>
                {data ? (
                    data?.map((player, index) => {
                        return (
                            <div   className={`lineupPlayerNameContainer ${index % 2 === 0 ? 'stripped-row' : 'normal-row'}`}>
                                <span className='lineupPlayerNumberAndName'>{player.jerseyNumber}</span>
                                <span
                                    className='lineupPlayerName'>{player.name}</span>
                            </div>
                        );
                    })
                ) : (
                    <span style={{color: '#000000', textAlign: 'center'}}>
                        {lang === 'en' ? 'Loading...' : 'Yükleniyor...'}
                    </span>
                )}
            </div>
        </div>
    );
}

export default LineupCard;
