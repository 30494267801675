import React, {useContext} from 'react';
import './Footer.css'
import Insta from '../../assets/icons/instagram-dark.svg'
import Facebook from '../../assets/icons/facebook-dark.svg'
import Twitter from '../../assets/icons/twitter-dark.svg'
import TR from '../../assets/flags/TR.png'
import EN from '../../assets/flags/EN.png'
import ES from '../../assets/flags/ES.png'
import DE from '../../assets/flags/DE.png'
import FR from '../../assets/flags/FR.png'
import logo2 from '../../assets/icons/logo2.png'
import {Link, useLocation} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

function Footer(props) {
    const {lang, i18String, geo} = useContext(AppContext);

    const currentRoutes = useLocation();

    const pathName = currentRoutes.pathname;

    return (
        <>
            {
                pathName !== '/landing' && pathName !== '/android' &&
                 <div className={'footer-general-section'}>
                    <div className='const-width footer-top-container'>
                        <div className={'footer-container'}>
                <span className='footer-ul-title'>
                Stats 365
                </span>
                            <ul className='footer-ul-container'>
                                <li className='footer-li'>
                                    <Link target="_blank" to={'/terms'}>{i18String('Terms & Conditions')}</Link>
                                </li>
                                <li className='footer-li'>
                                    <Link target="_blank" to={'/privacy'}>{i18String('Privacy')}</Link>
                                </li>
                                <li className='footer-li'>
                                    <Link target="_blank" to={'/contact'}>{i18String('Contact')}</Link>
                                </li>
                            </ul>
                        </div>

                        <div className={'footer-container'}>
                <span className='footer-ul-title'>
                Takip Et
                </span>
                            <ul className='footer-ul-container'>
                                <li className='footer-li'>
                                    <img className='social-media-icons' src={Insta} alt=""/>
                                    Instagram
                                </li>
                                <li className='footer-li'>
                                    <img className='social-media-icons' src={Facebook} alt=""/>
                                    Facebook
                                </li>
                                <li className='footer-li'>
                                    <img className='social-media-icons' src={Twitter} alt=""/>
                                    Twitter
                                </li>
                            </ul>
                        </div>

                        <div className={'footer-container'}>
                <span className='footer-ul-title'>
                Mobil Uygulamalar
                </span>
                            <ul className='footer-ul-container'>
                                <li className='footer-li'>
                                    <a href="https://apps.apple.com/us/app/stats365-soccer-stats-scores/id1592722868"
                                       target="_blank" rel="noopener">
                                        <div className="footer_app_store"></div>
                                    </a>
                                </li>
                                <li className='footer-li'>
                                    <a href="https://stats-365.com/android"
                                       target="_blank" rel="noopener">
                                        <div className="footer_play_store"></div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className={'footer-container'}>
                <span className='footer-ul-title'>
                {i18String('Language')}
                </span>
                            <ul className='footer-ul-container'>
                                <li className='footer-li'>
                                    <img className='flags' src={TR} alt=""/>
                                    Türkçe
                                </li>
                                <li className='footer-li'>
                                    <img className='flags' src={EN} alt=""/>
                                    İngilizce
                                </li>
                                <li className='footer-li'>
                                    <img className='flags' src={DE} alt=""/>
                                    Almanca
                                </li>
                                <li className='footer-li'>
                                    <img className='flags' src={FR} alt=""/>
                                    Fransızca
                                </li>
                                <li className='footer-li'>
                                    <img className='flags' src={ES} alt=""/>
                                    İspanyolca
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='const-width footer-bottom-container'>
                        <div style={{display: 'flex', justifyContent: 'center', margin: '10px 0'}}>
                            <img className='logo2-style' src={logo2} alt=""/>
                        </div>

                        <span className='copyright-text'>Country: {geo?.country} | CountryCode: {geo?.countryCode}</span>
                        <span className='copyright-text'>Copyright © 2023 Stats 365</span>
                        <h1 className='footer-title-h1'>{i18String('LiveScore - Latest Football Scores, Results, Fixtures and ables')}</h1>
                        <p className='gamble-text'>{i18String('Our content is intended for users over 18 years of age. It is used only for analysis and is ABSOLUTELY not used for betting!')}</p>
                        <a target={"_blank"} href={'https://www.begambleaware.org/'}
                           className='gamble-text'>{i18String('BEGAMBLEAWARE.ORG Gamble Responsibly. Gambling Therapy.')}</a>
                        <span className='followers-text'>{i18String('Followers must be 18+')}</span>
                        <span
                            className='footer-text'>{i18String('Stats365.com\'da yer alan tüm sayısal veriler, 1000\'den fazla futbol liginden canlı skorlar, puan durumu, gol atan oyuncular, futbol ilk yarı sonuçları, kırmızı kartlar, sarı kartlar, korner ve şut bilgileri, iddaa oranları, iddaa programı, maç sonuçları, istatistik ve tahmin bilgileri sadece bilgilendirme amaçlıdır. Bilgilerin doğruluğunu sağlamak için gereken gayret gösterilmektedir ancak bilgilerin doğruluğunun kontrolü kullanıcıların kendi sorumluluğundadır. Oluşabilecek hatalardan, maddi/manevi zararlardan Stats365.com sorumlu tutulamaz.')}</span>
                    </div>

                </div>
            }
        </>
    );
}

export default Footer;
