import React, {useContext} from "react";
import {AppContext} from "../../context/AppContext";
import dayjs from "dayjs";
import yCard from "../../assets/icons/mackolik-ycard.gif";
import rCard from "../../assets/icons/mackolik-rcard.gif";
import foul from "../../assets/icons/foul-icon.png";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";
import {saveMatchToLocalStorage} from "../local-storage/ManageLocalStorage";

function RefereeSeasonMatches(props) {
    const {timeZone} = useContext(AppContext);

    const {refereeAllMatchesData, i18String} = props;
    const matchDateTime = (value) => {
        return dayjs(value).tz(timeZone).format("DD/MM/YY")
    }

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div>
            {/*MATCHES HEADER*/}
            <div className='referee-matches-row-container-web-header'>
                <div className='referee-match-header-cell'>{i18String('League')}</div>
                <div className='referee-match-header-cell'>{i18String('Date')}</div>
                <div className='referee-match-header-cell'>{i18String('Match')}</div>
            </div>
            <div className='referee-matches-row-container'>
                <div></div>
                <div></div>
                <div className='referee-match-header-cell'>{i18String('Y.C.')}</div>
                <div className='referee-match-header-cell'>{i18String('R.C.')}</div>
                <div className='referee-match-header-cell'>{i18String('Foul')}</div>
                <div className='referee-match-header-cell'>{i18String('Pen')}</div>
                <div className='referee-match-header-cell'>{i18String('FT')}</div>
            </div>
            {
                refereeAllMatchesData && refereeAllMatchesData.map(match => {
                    return (
                        <div key={match.id}>
                            {/*MATCHES CONTENT MOBILE*/}
                            <div className='referee-matches-row-container-mobile'>
                                <div>
                                    <span className='referee-match-date'>{matchDateTime(match.dateTimeUtc)}</span>
                                </div>

                                <div className='referee-match-teams-and-score-section'>
                                    <div className='referee-match-teams-and-score-container referee-match-home-team-container'>
                                        <a target={"_blank"} href={`/team-detail/${match.homeTeamId}`}
                                           className={'referee-match-team-names'}>{match.homeTeam}</a>
                                    </div>

                                    <div className='referee-match-score-container'>
                                        <span className='referee-match-team-names referee-match-score'>{match.hscore} - {match.ascore}</span>
                                    </div>

                                    <div className='referee-match-teams-and-score-container'>
                                        <a target={"_blank"} href={`/team-detail/${match.awayTeamId}`}
                                           className={'referee-match-team-names'}>{match.awayTeam}</a>
                                    </div>
                                </div>

                                <div className='referee-match-teams-mobile'>
                                    <span className='referee-match-team-names'>{match.homeTeam}</span>
                                    <span className='referee-match-team-names'>{match.awayTeam}</span>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hyellow + match.ayellow > 0 &&
                                        <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={yCard} alt="Yellow Card"/>
                                    }
                                    <div className='referee-match-cell'>
                                        <span>{match.hyellow}</span>
                                        <span>{match.ayellow}</span>
                                    </div>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hred + match.ared > 0 &&
                                        <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={rCard} alt="Red Card"/>
                                    }
                                    <div className='referee-match-cell'>
                                        <span>{match.hred}</span>
                                        <span>{match.ared}</span>
                                    </div>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hfoul}</span>
                                    <span>{match.afoul}</span>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hpen}</span>
                                    <span>{match.apen}</span>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hscore}</span>
                                    <span>{match.ascore}</span>
                                </div>
                            </div>

                            {/*WEB*/}
                            <div className='referee-matches-row-container-web'>

                                <div className='referee-flag-and-comp-name-container'>
                                    <img
                                        className="referee-country-flag"
                                        src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${match.areaId}.png`}
                                        onError={(e) => onImageError(e)}
                                        alt={match.areaName}
                                    />
                                    <span title={match.competitionName} className='referee-match-league-name'>{match.competitionName}</span>
                                </div>
                                <div className='referee-match-date-container'>
                                    <span className='referee-match-date'>{matchDateTime(match.dateTimeUtc)}</span>
                                </div>

                                {/*EMPTY BLOCKS*/}
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>

                                <div className='referee-match-cell'>
                                    {
                                        match.hfoul > 0 &&
                                        <span style={{display: 'flex', placeItems: 'center'}} title={i18String('Foul')}>
                                            {match.hfoul}x
                                            <img style={{width: '15px', height: '15px'}} src={foul} alt="Foul"/>
                                        </span>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.hpen > 0 &&
                                        <span title={i18String('Penalty')}>{match.hpen}x<span className='penalty-icon-cont'>P</span></span>
                                    }
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hred > 0 &&
                                        <div className='referee-match-cell'>
                                            <span title={i18String('Red Card')}>{match.hred}x</span>
                                            <img style={{width: '10px', height: '13px', marginLeft: '5px'}} src={rCard} alt="Red Card"/>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hyellow > 0 &&
                                        <div className='referee-match-cell'>
                                            <span title={i18String('Yellow Card')}>{match.hyellow}x</span>
                                            <img style={{width: '10px', height: '13px', marginLeft: '5px'}} src={yCard} alt="Yellow Card"/>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-teams-and-score-container referee-match-home-team-container'>
                                    <a target={"_blank"} href={`/team-detail/${match.homeTeamId}`}
                                       className={'referee-match-team-names'}>{match.homeTeam}</a>
                                </div>

                                <div className='referee-match-score-container'
                                     onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                         matchId: match.id,
                                         home: match.homeTeam,
                                         homeId: match.homeTeamId,
                                         away: match.awayTeam,
                                         awayId: match.awayTeamId,
                                         time: match.dateTimeUtc,
                                         seasonId: match.seasonId
                                     })}
                                >
                                    <span className='referee-match-team-names referee-match-score'>{match.hscore} - {match.ascore}</span>
                                </div>

                                <div className='referee-match-teams-and-score-container'>
                                    <a target={"_blank"} href={`/team-detail/${match.awayTeamId}`}
                                       className={'referee-match-team-names'}>{match.awayTeam}</a>
                                </div>

                                <div className='referee-match-teams-mobile'>
                                    <span className='referee-match-team-names'>{match.homeTeam}</span>
                                    <span className='referee-match-team-names'>{match.awayTeam}</span>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.ayellow > 0 &&
                                        <div className='referee-match-cell'>
                                            <span title={i18String('Yellow Card')}>{match.ayellow}x</span>
                                            <img style={{width: '10px', height: '13px', marginLeft: '5px'}} src={yCard} alt="Yellow Card"/>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.ared > 0 &&
                                        <div className='referee-match-cell'>
                                            <span title={i18String('Red Card')}>{match.ared}x</span>
                                            <img style={{width: '10px', height: '13px', marginLeft: '5px'}} src={rCard} alt="Red Card"/>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.apen > 0 &&
                                        <span title={i18String('Penalty')}>{match.apen}x<span className='penalty-icon-cont'>P</span></span>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.afoul > 0 &&
                                        <span style={{display: 'flex', placeItems: 'center'}} title={i18String('Foul')}>
                                            {match.afoul}x
                                            <img style={{width: '15px', height: '15px'}} src={foul} alt="Foul"/>
                                        </span>
                                    }
                                </div>

                                {/*EMPTY BLOCKS*/}
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default RefereeSeasonMatches;
