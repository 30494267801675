import React, {useContext} from 'react';
import padLock from "../../../assets/icons/padlock.png";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../context/AppContext";

function OddsStatsRow(props) {
    const {isPro} = useContext(AppContext);

    const {value, index, statsType} = props;
    const navigate = useNavigate();

    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    return (
        <td className='stat-style-width-odds'>
            {/*FOR MOBILE*/}
            {/*<div className='stats-container-for-mobile'>
                {
                    isPro ?
                        <td className='stats-container-for-mobile' style={statsColor(props.stat.val.slice(0, -1), props.stat.name)}>
                            {
                                <div>
                                    <span className='stats-type-mobile'>{props.stat.name}</span>
                                    <span className='stats-type-for-web'
                                          style={statsColorForMobile(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                                </div>
                            }
                        </td>
                        :
                        <div>
                            {
                                index < 10 && statsType === 'goalStats' ?
                                    <td className='stats-container-for-mobile' style={statsColor(props.stat.val.slice(0, -1), props.stat.name)}>
                                        {
                                            <div>
                                                <span className='stats-type-mobile'>{props.stat.name}</span>
                                                <span className='stats-type-for-web'
                                                      style={statsColorForMobile(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                                            </div>
                                        }
                                    </td>
                                    :
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <td>
                                            <div onClick={() => toJoinPremium()}>
                                                <span className={'stats-type-mobile'}>{props.stat.name}</span>
                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                            </div>
                                        </td>
                                    </div>
                            }
                        </div>
                }
            </div>*/}
            {/*FOR WEB*/}
            <div className='stats-container-for-web'>
                {
                    isPro ?
                        <td>
                            {
                                <div>
                                        <span className='stats-type-for-web'>{value}</span>
                                </div>
                            }
                        </td>
                        :
                        <div>
                            {
                                index < 10 && statsType === 'goalStats' ?
                                    <td>
                                        <span className='stats-type-for-web'>{value}</span>
                                    </td>
                                    :
                                    <td>
                                        <div onClick={() => toJoinPremium()}>
                                            <img className='pad-lock-icon' src={padLock} alt=""/>
                                        </div>
                                    </td>
                            }
                        </div>
                }
            </div>
        </td>
    );
}

export default OddsStatsRow;
