import React, {useContext, useEffect, useState} from 'react';
import {fetchMatchPlus} from "../../../api";
import HomeMatchPlus from "./home-match-plus/HomeMatchPlus";
import {AppContext} from "../../../context/AppContext";
import {Tab, Tabs} from "@mui/material";
import AwayMatchPlus from "./away-match-plus/AwayMatchPlus";

function MatchPlus(props) {
    const {i18String} = useContext(AppContext);
    const {matchTeamsDetail} = props;

    const [tabType, setTabType] = useState('Goals');
    const [tabToggle, setTabToggle] = useState(0);

    const statsButtons = [
        {text: `${i18String('P-Goals')}`, type: 'Goals', toggle: 0},
        {text: `${i18String('P-Corners')}`, type: 'Corners', toggle: 1},
        {text: `${i18String('P-Cards')}`, type: 'Cards', toggle: 2},
    ]

    const clickTabs = (tab) => {
        setTabType(tab.type);
        setTabToggle(tab.toggle);
    }

    return (
        <div className='match-plus-general-section'>
            <div className='match-details-player-stats-tabs-section'>
                <Tabs
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{style: {display: "none"}}}
                    value={tabToggle}
                >
                    {
                        statsButtons.map((tabs, index) => {
                            return (
                                <Tab
                                    key={tabs.type}
                                    className={'best-teams-tab'}
                                    label={tabs.text}
                                    onClick={() => clickTabs(tabs)}
                                    sx={{ textTransform: "none" }}
                                    style={{
                                        color: tabToggle === index ? '#3e2d56' : '#485b74' // Aktif sekme siyah, diğerleri varsayılan
                                    }}
                                >
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>

            <div className='mp-teams-general-container'>
                <HomeMatchPlus matchTeamsDetail={matchTeamsDetail} tabType={tabType}/>
                <AwayMatchPlus matchTeamsDetail={matchTeamsDetail} tabType={tabType}/>
            </div>
        </div>
    );
}

export default MatchPlus;
