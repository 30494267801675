import React, {useContext, useEffect, useState} from 'react';
import BetModeMatches from "./BetModeMatches";
import {AppContext} from "../../context/AppContext";
import {Link} from "react-router-dom";
import LiveScoresTableHeader from "../live-scores/LiveScoresTableHeader";
import {fetchLiteMatches, fetchLiveScoresInstant} from "../../api";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";

function BetModeByLeagueContent(props) {

    const {favBetModeMatches, i18String} = useContext(AppContext);
    const {fetchFavBetModeMatches, liveScoresData} = props;

    const [statsHeader, setStatsHeader] = useState({
        liveScoresTableHeader: [
            {name: 'Y. Card', text: `${i18String('Y. Card')}`},
            {name: 'Corner', text: `${i18String('Corner')}`},
            {name: 'Shots', text: `${i18String('Shots')}`},
            {name: 'Target', text: `${i18String('S.Target')}`},
            {name: 'Foul', text: `${i18String('Foul')}`},
            {name: 'Poss.', text: `${i18String('Poss.')}`},
        ]
    })

    const statStyleWidth = () => {
        return 'bet-mode-stats-header'
    }

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div>
            {

                favBetModeMatches &&
                favBetModeMatches.length > 0 &&
                <div>
                    <table className="fixtures-table-section">
                        <tbody>
                        <tr className="score-radar-league-name-container">
                            {
                                <td className="score-radar-league-name-and-flag-container">
                                    <span className="score-radar-area-info-container table-11px-area-header">Favorites</span>
                                </td>
                            }
                            {
                                statsHeader['liveScoresTableHeader'].map((stHeader) => {
                                    return (
                                        <td key={stHeader.name} className={statStyleWidth()}>
                                            <div className='score-radar-table-header-container'>
                                                <span className="general-11px-font-style">{stHeader.text}</span>
                                            </div>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        </tbody>
                    </table>
                    <BetModeMatches league={{matches: fetchFavBetModeMatches}}/>
                </div>
            }
            {
                liveScoresData && liveScoresData.map((league) => {
                    return (
                        <div key={`${league.league}-${league.leagueId}`}>
                            <table className="fixtures-table-section">
                                <tbody>
                                <tr className="score-radar-league-name-container">
                                    {
                                        <td className="score-radar-league-name-and-flag-container">
                                            <div className='score-radar-flag-and-league-container'>
                                                <img className="score-radar-country-flag"
                                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                     onError={(e) => onImageError(e)}
                                                     alt={`${league.area} - ${league.league}`}
                                                />
                                                <a
                                                    target='_blank'
                                                    href={`/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${league.leagueId}/${league.seasonId}/stats`}
                                                    className="bet-mode-area-info-container">{league.area} - {league.league}</a>
                                            </div>
                                        </td>
                                    }
                                    {
                                        statsHeader['liveScoresTableHeader'].map((stHeader) => {
                                            return (
                                                <td key={stHeader.name} className={statStyleWidth()}>
                                                    <div className='score-radar-table-header-container'>
                                                        <span className="general-11px-font-style">{stHeader.text}</span>
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                                </tbody>
                            </table>
                            <BetModeMatches league={league}/>

                        </div>
                    )
                })
            }
        </div>
    );
}

export default BetModeByLeagueContent;
