import React, {useContext} from 'react';
import {AppContext} from "../../context/AppContext";

function StatsRowNoInfo(props) {
    const {i18String} = useContext(AppContext);
  return (
    <td className={props.borderStyle}>
      <div className={'no-info-stats-row'}>{i18String('No data found')}</div>
    </td>
  );
}

export default StatsRowNoInfo;
