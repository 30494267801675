import React from 'react';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from "@mui/material";
import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";

function MatchDetailsStatistics(props) {

    const {statisticData, lang, i18String} = props;
    const params = useParams();


    const BorderLinearProgressHome = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 1,
        width: 50,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 20 : 80],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 1,
            backgroundColor: theme.palette.mode === 'light' ? '#3069b0' : '#b4c9e1',
        },
    }));

    const BorderLinearProgressAway = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 1,
        width: 50,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#f8d1af',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 1,
            backgroundColor: theme.palette.mode === 'light' ? '#ea7814' : '#f8d1af',
        },
    }));

    const statsProgressBar = (name, val) => {
        if (name === 'Attacks' && val > 100) {
            return 90
        } else {
            return val
        }
    }

    const statsLists = {
        'Ball possession': `${i18String('Ball possession')}`,
        'Dangerous Attacks': `${i18String('Dangerous Attacks')}`,
        'Attacks': `${i18String('Attacks')}`,
        'Total shots': `${i18String('Total shots')}`,
        'Shots on target': `${i18String('Shots on target')}`,
        'Shots off target': `${i18String('Shots off target')}`,
        'Blocked shots': `${i18String('Blocked shots')}`,
        'Corner kicks': `${i18String('Corner kicks')}`,
        'Yellow cards': `${i18String('Yellow cards')}`,
        'Red cards': `${i18String('Red cards')}`,
        'Fouls': `${i18String('Fouls')}`,
        'Offsides': `${i18String('Offsides')}`,
        'Shots inside box': `${i18String('Shots inside box')}`,
        'Shots outside box': `${i18String('Shots outside box')}`,
        'Hit woodwork': `${i18String('Hit woodwork')}`,
        'Passes': `${i18String('Passes')}`,
        'Accurate passes': `${i18String('Accurate passes')}`,
        'Crosses': `${i18String('Crosses')}`,
        'Duels won': `${i18String('Duels won')}`,
        'Defending': `${i18String('Defending')}`,
        'Goalkeeper saves': `${i18String('Goalkeeper saves')}`,
        'Interceptions': `${i18String('Interceptions')}`,
        'Tackles': `${i18String('Tackles')}`,
    };

    const statsTitleLists = {
        'Main Stats': `${i18String('Main Stats')}`,
        'Extra': `${i18String('Extra')}`,
        'Passes': `${i18String('Passes')}`,
        'Duels': `${i18String('Duels')}`,
        'Defending': `${i18String('Defending')}`,
        'Extra Stats': `${i18String('Extra Stats')}`,
    };

    return (
        <div className={
            params.tabType === 'goals' || params.tabType === 'cards' || params.tabType === 'corners'
                ? 'match-detail-statistics-section-display-none'
                : 'match-detail-statistics-section'
        }>
            {
                statisticData &&
                <div>
                    {
                        lang === 'tr' ?
                            <div className='statistics-and-standings-title'>İstatistikler</div>
                            :
                            <div className='statistics-and-standings-title'>Statistics</div>
                    }
                </div>
            }

            {/*FOR WEB*/}
            <div className='statistics-content-for-web'>
                {
                    statisticData &&
                    statisticData.map(stat => {
                        return (
                            <div>
                                <div className='statistic-sub-title'>
                                    {statsTitleLists[stat.name]}
                                </div>
                                {
                                    stat.stats.map((st, index) => {
                                        return (
                                            <div
                                                className={index % 2 === 0 ? 'stats-and-progress-container' : 'stats-and-progress-container-stripped'}>
                                                <div style={{display: "flex"}}>
                                                    <Box sx={{flexGrow: 1, scale: "-1 1"}}>
                                                        <BorderLinearProgressHome variant="determinate" value={statsProgressBar(st.name, st.home)}/>
                                                    </Box>
                                                </div>
                                                <div className='match-detail-percent-value'>
                                                    {st.home}
                                                </div>
                                                <div className='statistic-name'>
                                                    <span>{statsLists[st.name]}</span>
                                                </div>
                                                <div className='match-detail-percent-value'>
                                                    {st.away}
                                                </div>
                                                <div>
                                                    <Box sx={{flexGrow: 1}}>
                                                        <BorderLinearProgressAway variant="determinate" value={statsProgressBar(st.name, st.away)}/>
                                                    </Box>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>

            {/*FOR MOBILE*/}
            <div className='statistics-content-for-mobile'>
                {
                    statisticData &&
                    statisticData.map(stat => {
                        return (
                            <div className='stats-and-progress-row'>
                                {
                                    stat.stats.map((st, index) => {
                                        return (
                                            <div
                                                className='stats-and-progress-container'>
                                                <div className='match-detail-percent-value'>
                                                    {st.home}
                                                </div>
                                                <div className='statistic-name'>
                                                    <span>{statsLists[st.name]}</span>
                                                </div>
                                                <div className='match-detail-percent-value'>
                                                    {st.away}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default MatchDetailsStatistics;
