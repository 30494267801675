import React, {useContext, useEffect, useState} from 'react';
import {Tab, Tabs} from "@mui/material";
import {
    fetchRefereeAllMatches,
    fetchRefereeAllStats, fetchRefereeInfo,
    fetchRefereeSeasonMatches,
    fetchRefereeSeasonStats
} from "../../api";
import RefereeSeasonStats from "./RefereeSeasonStats";
import RefereeAllStats from "./RefereeAllStats";
import RefereeSeasonMatches from "./RefereeSeasonMatches";
import {useParams} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import RefereeAllMatches from "./RefereeAllMatches";
import referee from "../../assets/icons/referee.png";

function Referee(props) {
    const {i18String} = useContext(AppContext);
    const params = useParams();

    const [refereeInfoData, setRefereeInfoData] = useState(null);
    const [refereeSeasonStatsData, setRefereeSeasonStatsData] = useState(null);
    const [refereeAllStatsData, setRefereeAllStatsData] = useState(null);
    const [refereeSeasonMatchesData, setRefereeSeasonMatchesData] = useState(null);
    const [refereeAllMatchesData, setRefereeAllMatchesData] = useState(null);
    const [tabToggle, setTabToggle] = useState(0);

    useEffect(() => {
        fetchRefereeInfo(params?.refId).then(data => {
            setRefereeInfoData(data.data.data);
        });
        fetchRefereeSeasonStats(params?.refId, params?.seasonId).then(data => {
            setRefereeSeasonStatsData(data.data.data);
        });
        fetchRefereeAllStats(params?.refId).then(data => {
            setRefereeAllStatsData(data.data.data);
        });

        fetchRefereeSeasonMatches(params?.refId, params?.seasonId).then(data => {
            setRefereeSeasonMatchesData(data.data.data);
        });

        fetchRefereeAllMatches(params?.refId).then(data => {
            setRefereeAllMatchesData(data.data.data);
        });
    }, [params?.refId]);

    const refereeTabs = [
        {
            name: `${i18String('Season Stats')}`, type: 'seasonStats', toggle: 0
        },
        {
            name: `${i18String('All Stats')}`, type: 'allStats', toggle: 1
        },
        {
            name: `${i18String('Season Matches')}`, type: 'seasonMatches', toggle: 2
        },
        {
            name: `${i18String('All Matches')}`, type: 'allMatches', toggle: 3
        }
    ]

    const clickTabs = (tab) => {
        setTabToggle(tab.toggle);
    }

    return (
        <div className='referee-page-section'>

            {
                refereeInfoData &&
                <div className='referee-name-title-row'>
                    <img style={{width: '60px', height: '60px'}} src={referee} alt={`Referee`}/>
                    <div className='referee-name-and-flag-container'>
                        <div className='referee-name-container'>
                            <span className='referee-name'>{refereeInfoData.name}</span>
                        </div>
                        <div className='referee-country-and-flag-container'>
                            <img className='referee-country-flag' src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${refereeInfoData.countryId}.png`}
                                 alt={refereeInfoData.country}/>
                            <span className='referee-country-name'>{refereeInfoData.country}</span>
                        </div>
                    </div>
                </div>
            }


            <div className='match-details-player-stats-tabs-section' style={{borderBottom: '1px solid #dfdfdf'}}>
                <Tabs
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{style: {display: "none"}}}
                    value={tabToggle}
                >
                    {
                        refereeTabs.map((tabs, index) => {
                            return (
                                <Tab
                                    key={tabs.type}
                                    label={tabs.name}
                                    onClick={() => clickTabs(tabs)}
                                    sx={{ textTransform: "none" }}
                                    style={{
                                        color: tabToggle === index ? '#3e2d56' : '#485b74'
                                    }}
                                >
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>

            {
                tabToggle === 0 && <RefereeSeasonStats refereeSeasonStatsData={refereeSeasonStatsData}/>
            }

            {
                tabToggle === 1 && <RefereeAllStats refereeAllStatsData={refereeAllStatsData}/>
            }

            {
                tabToggle === 2 && <RefereeSeasonMatches refereeSeasonMatchesData={refereeSeasonMatchesData} i18String={i18String}/>
            }

            {
                tabToggle === 3 && <RefereeAllMatches refereeAllMatchesData={refereeAllMatchesData} i18String={i18String}/>
            }
        </div>
    );
}

export default Referee;
