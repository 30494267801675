import React, {useContext} from 'react';
import padLock from '../../assets/icons/padlock.png'
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

function StatsRow(props) {
    const {isPro} = useContext(AppContext);

    const {statsType, index} = props;

    const navigate = useNavigate();

    const statStyleWidth = () => {
        /*if (statsType === 'avgStats') {
            return 'stat-style-width-red'
        } else {
            return 'stat-style-width-other'
        }*/
        return 'stat-style-width-other'
    }

    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    const statsColor = (stats, statsName) => {
        if (statsType !== 'refereeStats') {
            if (statsType === 'avgStats') {
                if (statsName === 'Goals PG') {
                    if (stats >= 3.5) {
                        return {
                            background: '#72da2373',
                            color: '#095035',
                            padding: '4px',
                            flex: 1
                        }
                    } else {
                        return {
                            padding: '4px',
                            flex: 1
                        }
                    }
                } else if (statsName === 'Corners PG') {
                    if (stats >= 12) {
                        return {
                            background: '#72da2373',
                            color: '#095035',
                            padding: '4px',
                            flex: 1
                        }
                    } else {
                        return {
                            padding: '4px',
                            flex: 1
                        }
                    }
                } else if ((statsName === 'Cards PG')) {
                    if (stats >= 5) {
                        return {
                            background: '#72da2373',
                            color: '#095035',
                            padding: '4px',
                            flex: 1
                        }
                    } else {
                        return {
                            padding: '4px',
                            flex: 1
                        }
                    }
                }
            } else {
                if (stats >= 90) {
                    return {
                        background: '#72da2373',
                        color: '#095035',
                        padding: '4px',
                        flex: 1
                    }
                } else if (stats >= 80) {
                    return {
                        background: '#72da233d',
                        color: '#095035',
                        padding: '4px',
                        flex: 1
                    }
                } else if (stats >= 60) {
                    return {
                        background: '#f6f7cd',
                        color: '#656500',
                        padding: '4px',
                        flex: 1
                    }
                } else {
                    return {
                        /*background: '#ffe9e6',*/
                        background: '#fff',
                        color: 'var(--H3Dark0)',
                        padding: '4px',
                        flex: 1
                    }
                }
            }
        } else {
            return {
                padding: '4px',
            }
        }
    }

    const statsColorForWeb = (stats, statsName) => {
        if (statsType !== 'refereeStats') {
            if (statsType === 'avgStats') {
                if (statsName === 'Goals PG') {
                    if (stats >= 3.5) {
                        return {
                            background: '#72da2373',
                            color: '#095035',
                            padding: '4px',
                        }
                    } else {
                        return {
                            padding: '4px',
                        }
                    }
                } else if (statsName === 'Corners PG') {
                    if (stats >= 12) {
                        return {
                            background: '#72da2373',
                            color: '#095035',
                            padding: '4px',
                        }
                    } else {
                        return {
                            padding: '4px',
                        }
                    }
                } else if ((statsName === 'Cards PG')) {
                    if (stats >= 5) {
                        return {
                            background: '#72da2373',
                            color: '#095035',
                            padding: '4px',
                        }
                    } else {
                        return {
                            padding: '4px',
                        }
                    }
                }
            } else {
                if (stats >= 90) {
                    return {
                        background: '#72da2373',
                        color: '#095035',
                        padding: '4px',
                    }
                } else if (stats >= 80) {
                    return {
                        background: '#72da233d',
                        color: '#095035',
                        padding: '4px',
                    }
                } else if (stats >= 60) {
                    return {
                        background: '#f6f7cd',
                        color: '#656500',
                        padding: '4px',
                    }
                } else {
                    return {
                        background: '#ff',
                        color: '#656500',
                        padding: '4px',
                    }
                }
            }
        }
    }

    const statsColorForMobile = (stats, statsName) => {
        if (statsType !== 'refereeStats') {
            if (statsType === 'avgStats') {
                if (statsName === 'Goals PG') {
                    if (stats >= 3.5) {
                        return {
                            color: '#095035',
                            padding: '4px 7px'
                        }
                    }
                    return {
                        border: '1px solid #d3d3d3',
                        borderRadius: '5px',
                        padding: '2px'
                    }
                } else if (statsName === 'Corners PG') {
                    if (stats >= 12) {
                        return {
                            color: '#095035',
                            padding: '4px 7px'
                        }
                    }
                    return {
                        border: '1px solid #d3d3d3',
                        borderRadius: '5px',
                        padding: '2px'
                    }
                } else if ((statsName === 'Cards PG')) {
                    if (stats >= 5) {
                        return {
                            color: '#095035',
                            padding: '4px 7px'
                        }
                    }
                    return {
                        border: '1px solid #d3d3d3',
                        borderRadius: '5px',
                        padding: '2px'
                    }
                }
            } else {
                if (stats >= 90) {
                    return {
                        /*color: '#095035',*/
                        color: 'var(--text-color00)',
                        padding: '4px 7px'
                    }
                } else if (stats >= 80) {
                    return {
                        /*color: '#095035',*/
                        color: 'var(--text-color00)',
                        padding: '4px 7px'
                    }
                } else if (stats >= 60) {
                    return {
                        /*color: '#656500',*/
                        color: 'var(--text-color00)',
                        padding: '4px 7px'
                    }
                } else {
                    return {
                        /*color: '#ff7563',*/
                        color: 'var(--text-color00)',
                        padding: '4px 7px'
                    }
                }
            }
        } else {
            if (
                (statsName === 'Yellows PG' && stats > 6.0) ||
                (statsName === 'Reds PG' && stats > 1.0) ||
                (statsName === 'Pens PG' && stats > 1.0) ||
                (statsName === 'Fouls PG' && stats > 30.0)
            ) {
                return {
                    background: '#72da2373',
                    color: '#095035',
                    padding: '4px',
                    flex: 1
                };
            }
        }
    }

    return (
        <td className={statStyleWidth()}>
            {/*FOR MOBILE*/}
            <div className='stats-container-for-mobile'>
                {
                    isPro ?
                        <td className='stats-container-for-mobile'
                            style={statsColor(props.stat.val.slice(0, -1), props.stat.name)}>
                            {
                                <div>
                                    <span className='stats-type-mobile'>{props.stat.name}</span>
                                    <span className='stats-type-for-web'
                                          style={statsColorForMobile(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                                </div>
                            }
                        </td>
                        :
                        <div>
                            {
                                index < 10 && statsType === 'goalStats' ?
                                    <td className='stats-container-for-mobile'
                                        style={statsColor(props.stat.val.slice(0, -1), props.stat.name)}>
                                        {
                                            <div>
                                                <span className='stats-type-mobile'>{props.stat.name}</span>
                                                <span className='stats-type-for-web'
                                                      style={statsColorForMobile(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                                            </div>
                                        }
                                    </td>
                                    :
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <td>
                                            <div onClick={() => toJoinPremium()}>
                                                <span className={'stats-type-mobile'}>{props.stat.name}</span>
                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                            </div>
                                        </td>
                                    </div>
                            }
                        </div>
                }
            </div>
            {/*FOR WEB*/}
            <div className='stats-container-for-web'>
                {
                    isPro ?
                        <td style={statsColorForWeb(props.stat.val.slice(0, -1), props.stat.name)}>
                            {
                                <div>
                                        <span className='stats-type-for-web'
                                              style={statsColorForMobile(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                                </div>
                            }
                        </td>
                        :
                        <div>
                            {
                                index < 10 && statsType === 'goalStats' ?
                                    <td style={statsColorForWeb(props.stat.val.slice(0, -1), props.stat.name)}>
                                        {
                                            <div>
                                        <span className='stats-type-for-web'
                                              style={statsColorForMobile(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                                            </div>
                                        }
                                    </td>
                                    :
                                    <td>
                                        <div onClick={() => toJoinPremium()}>
                                            <span className={'stats-type-mobile'}>{props.stat.name}</span>
                                            <img className='pad-lock-icon' src={padLock} alt=""/>
                                        </div>
                                    </td>
                            }
                        </div>
                }
            </div>
        </td>
    );
}

export default StatsRow;
