import React, {useContext, useEffect, useState} from 'react';
import livePng from '../../assets/icons/live-icon.png'
import dayjs from "dayjs";
import './Stats.css'
import '../../App.css'
import {fetchFavoritesMatches, fetchFixtures, fetchLiteMatches} from "../../api";
import LeagueAndMatchesTable from "./LeagueAndMatchesTable";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {Dialog, FormControl, FormLabel, InputLabel, List, ListItemButton, MenuItem, Select} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {i18n} from "../../i18n/i18n";
import filterIcon from '../../assets/icons/filterIcon.png'
import searchIcon from '../../assets/icons/searchIconWhite.png'
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {homepageMeta} from "../../SEO/SeoMetaTags";
import LinearComponent from "../linearComponent/LinearComponent";
import leagueDetails from "../league-details/LeagueDetails";

const statsList = [
    {name: 'goalStats'},
    {name: 'cornerStats'},
    {name: 'cardStats'},
]

const getLeagueIdentifier = (league, leagueId) => league + leagueId;

function Stats(props) {
    const {favMatches, lang, i18String, isPro} = useContext(AppContext);
    const navigate = useNavigate();

    const [selectedSelection, setSelectedSelection] = useState('');
    const [selectDialog, setSelectDialog] = useState(false);
    const [liveInstantMatches, setLiveInstantMatches] = useState(null);
    const [fixturesData, setFixturesData] = useState(null);
    const [liveData, setLiveData] = useState(null);
    const [fetchFavMatches, setFetchFavMatches] = useState([]);
    const [live, setLive] = useState(window.setLiveStatus || false);
    const [isShownDates, setIsShownDates] = useState(window.setShownDateStatus || false);
    const [isShownSearch, setIsShownSearch] = useState(window.setShownSearchStatus || false);
    const [toggles, setToggle] = useState(0);
    const [statsType, setStatsType] = useState('goalStats');
    const [statsNameForSelect, setStatsNameForSelect] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentStats, setCurrentStats] = useState('goalStatsHeader');
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
    const [selectedPercent, setSelectedPercent] = useState(0);
    const [selectedTypeForSelect, setSelectedTypeForSelect] = useState('All');

    //const [statsButtonsWeb, setStatsButtonsWeb] = useState([]);
    //const [statsButtonsMobile, setStatsButtonsMobile] = useState([]);
    const [week, setWeek] = useState([
        {day: new Date().toISOString().substr(0, 10),},
        {day: dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(2, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(3, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(4, 'days').format('YYYY-MM-DD')},
    ]);

    const statsButtonsWeb = [
        {text: `${i18String('Goals')}`, type: 'goalStats', header: 'goalStatsHeader', toggle: 0},
        {text: `${i18String('Corners')}`, type: 'cornerStats', header: 'cornerStatsHeader', toggle: 1},
        {text: `${i18String('Cards')}`, type: 'cardStats', header: 'cardsStatsHeader', toggle: 2},
        /*{text: 'AVG', type: 'avgStats', header: 'avgStatsHeader', toggle: 3},*/
        {text: `${i18String('Referee')}`, type: 'refereeStats', header: 'refereeStatsHeader', toggle: 4},
        {text: `${i18String('Odds')}`, type: 'oddsStats', header: 'oddsHeader', toggle: 5},
    ]

    const statsButtonsMobile = [
        {text: `${i18String('Goals')}`, type: 'goalStats', header: 'goalStatsHeader', toggle: 0},
        {text: `${i18String('Corners')}`, type: 'cornerStats', header: 'cornerStatsHeader', toggle: 1},
        {text: `${i18String('Cards')}`, type: 'cardStats', header: 'cardsStatsHeader', toggle: 2},
        {text: `${i18String('Avg')}`, type: 'avgStats', header: 'avgStatsHeader', toggle: 3},
        {text: `${i18String('Referee')}`, type: 'refereeStats', header: 'refereeStatsHeader', toggle: 4},
    ]

    useEffect(() => {
        const handleLiveIcon = () => {
            setLive(window.setLiveStatus);
        };
        const handleDateIcon = () => {
            setIsShownDates(window.setShownDateStatus);
        };
        const handleSearch = () => {
            setIsShownSearch(window.setShownSearchStatus);
        };

        // Toggle değişimini dinle
        window.addEventListener('liveChange', handleLiveIcon);
        window.addEventListener('shownDateStatus', handleDateIcon);
        window.addEventListener('shownSearchStatus', handleSearch);

        // Cleanup için event listener'ı kaldır
        return () => {
            window.removeEventListener('liveChange', handleLiveIcon);
            window.removeEventListener('shownDateStatus', handleDateIcon);
            window.removeEventListener('shownSearchStatus', handleSearch);
        };
    }, []);

    useEffect(() => {
        setStatsNameForSelect(i18String('All'));
    }, [i18String])

    // Update selected item index on Select
    useEffect(() => {
        /*const stats = ['80', '70', '60', '50'];
        const found = stats.includes(selectedPercent);

        if ((statsType === 'avgStats' && found) || (statsType !== 'avgStats' && !found)) {
            setSelectedPercent(0);
        }*/

        if (statsType === 'refereeStats') {
            setSelectedPercent(0);
        }

    }, [statsType]);

    const allPercentList = [
        {
            name: 'Goal',
            type: 'goalStats',
            header: 'goalStatsHeader',
            stats: [
                {
                    name: `${i18String('All')}`, value: {
                        type: 'All',
                        value: 0
                    }
                },
                {
                    name: '> 80', value: {
                        type: 'goal80',
                        value: 80
                    }
                },
                {
                    name: '> 70', value: {
                        type: 'goal70',
                        value: 70
                    }
                },
                {
                    name: '> 60', value: {
                        type: 'goal60',
                        value: 60
                    }
                },
                {
                    name: '> 50', value: {
                        type: 'goal50',
                        value: 50
                    }
                },
            ]
        },
        {
            name: 'Corner',
            type: 'cornerStats',
            header: 'cornerStatsHeader',
            stats: [
                {
                    name: `${i18String('All')}`, value: {
                        type: 'All',
                        value: 0
                    }
                },
                {
                    name: '> 80', value: {
                        type: 'corner80',
                        value: 80
                    }
                },
                {
                    name: '> 70', value: {
                        type: 'corner70',
                        value: 70
                    }
                },
                {
                    name: '> 60', value: {
                        type: 'corner60',
                        value: 60
                    }
                },
                {
                    name: '> 50', value: {
                        type: 'corner50',
                        value: 50
                    }
                },
            ]
        },
        {
            name: 'Card',
            type: 'cardStats',
            header: 'cardsStatsHeader',
            stats: [
                {
                    name: `${i18String('All')}`, value: {
                        type: 'All',
                        value: 0
                    }
                },
                {
                    name: '> 80', value: {
                        type: 'card80',
                        value: 80
                    }
                },
                {
                    name: '> 70', value: {
                        type: 'card70',
                        value: 70
                    }
                },
                {
                    name: '> 60', value: {
                        type: 'card60',
                        value: 60
                    }
                },
                {
                    name: '> 50', value: {
                        type: 'card50',
                        value: 50
                    }
                },
            ]
        },
        {
            name: 'Avg',
            type: 'avgStats',
            header: 'avgStatsHeader',
            stats: [
                {
                    name: `${i18String('All')}`, value: {
                        type: 'All',
                        value: 0
                    }
                },
                {
                    name: `${i18String('Goal AVG >= 3.0')}`,
                    value: {
                        type: 'Goals_3',
                        value: 3.0
                    }
                },
                {
                    name: `${i18String('Goal AVG >= 3.5')}`,
                    value: {
                        type: 'Goals_35',
                        value: 3.5
                    }
                },
                {
                    name: `${i18String('Goal AVG >= 4.0')}`,
                    value: {
                        type: 'Goals_4',
                        value: 4.0
                    }
                },
                {
                    name: `${i18String('Corner AVG >= 9')}`,
                    value: {
                        type: 'Corners_9',
                        value: 9.0
                    }
                },
                {
                    name: `${i18String('Corner AVG >= 10')}`,
                    value: {
                        type: 'Corners_10',
                        value: 10.0
                    }
                },
                {
                    name: `${i18String('Corner AVG >= 11')}`,
                    value: {
                        type: 'Corners_11',
                        value: 11.0
                    }
                },
                {
                    name: `${i18String('Corner AVG >= 12')}`,
                    value: {
                        type: 'Corners_12',
                        value: 12.0
                    }
                },
                {
                    name: `${i18String('Corner AVG >= 13')}`,
                    value: {
                        type: 'Corners_13',
                        value: 13.0
                    }
                },
                {
                    name: `${i18String('Card AVG >= 3')}`,
                    value: {
                        type: 'Cards_3',
                        value: 3.0
                    }
                },
                {
                    name: `${i18String('Card AVG >= 4')}`,
                    value: {
                        type: 'Cards_4',
                        value: 4.0
                    }
                },
                {
                    name: `${i18String('Card AVG >= 5')}`,
                    value: {
                        type: 'Cards_5',
                        value: 5.0
                    }
                },
                {
                    name: `${i18String('Card AVG >= 6')}`,
                    value: {
                        type: 'Cards_6',
                        value: 6.0
                    }
                },
                {
                    name: `${i18String('Card AVG >= 7')}`,
                    value: {
                        type: 'Cards_7',
                        value: 7.0
                    }
                }
            ]
        },
        {
            name: 'Referee',
            type: 'refereeStats',
            header: 'refereeStatsHeader',
            stats: [
                {
                    name: `${i18String('All')}`, value: {
                        type: 'All',
                        value: 0
                    }
                },
            ]
        },
    ]

    /*Stats CODES*/
    useEffect(() => {
        fetchFixtures(currentDate, lang).then((data) => {
            const fixtureData = data.data.data;
            setFixturesData(fixtureData);
            setLiveData(fixtureData);
        })
    }, [currentDate, lang]);

    const fixtureMatches = [];
    const liveLeaguesIndex = {};
    if (fixturesData) {
        fixturesData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area, seasonId} = leagues;
            const lid = getLeagueIdentifier(league, leagueId);
            liveLeaguesIndex[lid] = index;

            const matchesWithAllSelectedVal = leagues.matches.filter(match => {
                if (statsType !== 'refereeStats') {
                    /*const parts = typeof selectedPercent === 'string' ? selectedPercent.split(" - ") : null;
                    const result = parts && parts.length === 2 ? {type: parts[0], value: parts[1]} : null;*/
                    if (match[statsType]) {
                        if (statsType === 'avgStats') {
                            return match[statsType]?.every((item) => {
                                if (item.name.includes(selectedTypeForSelect?.split('_')[0])) {
                                    return item.val >= parseFloat(selectedPercent);
                                }
                                // Returning true if not 'Corners PG' to continue the every function.
                                return true;
                            });
                        } else {
                            if (match[statsType]) {
                                return match[statsType]?.every((item) => Number(item.val.slice(0, -1)) >= Number(selectedPercent));
                            }
                        }
                    } else {
                        if (parseInt(selectedPercent) === 0) {
                            return match
                        }
                    }
                } else {
                    return match
                }
            });
            matchesWithAllSelectedVal.forEach(match => {
                fixtureMatches.push({
                    leagueDetail: {leagueId, league, areaId, area, seasonId},
                    match
                });
            })
        })
    }

    let filteredInstantLiveMatches = [];
    if (liveInstantMatches) {
        /*const liveInstant = liveInstantMatches.filter(item => item.status === 'Playing');*/
        filteredInstantLiveMatches = fixtureMatches.map(matches => {
            const findLiveInstant = liveInstantMatches.find(match => match.id === matches.match?.id)
            return {
                ...matches,
                findLiveInstant
            }
        })
    }

    const matchesByLeagues = {};
    const leagueDetails = {};
    filteredInstantLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const asd = {...match, ...findLiveInstant};
        const lid = getLeagueIdentifier(leagueDetail.league, leagueDetail.leagueId);

        leagueDetails[lid] = leagueDetail;

        const matches = matchesByLeagues[lid] || [];
        matchesByLeagues[lid] = [...matches, asd];
    })

    const sections = Object.entries(matchesByLeagues).map(entry => {
        const [lid, matches] = entry;
        return {
            title: leagueDetails[lid],
            data: matches
        }
    })/*.sort(
        (a, b) => liveLeaguesIndex[a.title.league] - liveLeaguesIndex[b.title.league],
    );*/

    /*FAV MATCHES CODES*/
    useEffect(() => {
        if (favMatches.length > 0) {
            const matchesId = favMatches.map(item => item.match.id);

            fetchFavoritesMatches(matchesId).then(data => {
                const fetchMatches = data.data.data;
                setFetchFavMatches(fetchMatches);
            });

            const interval = setInterval(() => {
                fetchFavoritesMatches(matchesId).then(data => {
                    const fetchMatches = data.data.data;
                    setFetchFavMatches(fetchMatches);
                });
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [favMatches]);

    const favMatchesByLeagues = {};
    const favLeagueDetails = {};

    favMatches.forEach(favMatch => {
        const {leagueDetail, match} = favMatch;
        const lid = getLeagueIdentifier(leagueDetail.league, leagueDetail.leagueId);

        favLeagueDetails[lid] = leagueDetail;

        const fetchFavMatch = fetchFavMatches.find(item => item.id === match.id);
        const matches = favMatchesByLeagues[lid] || [];
        /*if (match.status !== 'Played') {
            favMatchesByLeagues[leagueDetail.leagueId] = [
                ...matches,
                {...match, ...fetchFavMatch},
            ];
        }*/
        favMatchesByLeagues[lid] = [
            ...matches,
            {...match, ...fetchFavMatch},
        ];
    });

    const favSections = Object.entries(favMatchesByLeagues).map(entry => {
        const [lid, matches] = entry;
        return {
            title: favLeagueDetails[lid],
            data: matches,
        };
    });

    /*LIVE MATCHES CODES*/
    useEffect(() => {
        const today = new Date().toISOString().substr(0, 10);
        if (currentDate === today) {
            fetchLiteMatches().then(data => {
                const instantMatches = data.data.data;
                setLiveInstantMatches(instantMatches);
            });

            const interval = setInterval(() => {
                fetchLiteMatches().then(data => {
                    const instantMatches = data.data.data;
                    setLiveInstantMatches(instantMatches);
                });
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [currentDate]);

    const liveMatches = [];
    const liveInstantLeaguesIndex = {};
    if (liveData) {
        liveData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area} = leagues;
            const lid = getLeagueIdentifier(league, leagueId);
            liveInstantLeaguesIndex[lid] = index;

            const matchesWithAllSelectedVal = leagues.matches.filter(match => {
                return liveInstantMatches?.some(instantMatch => {

                    if (statsType !== 'refereeStats') {
                        if (match[statsType]) {
                            if (statsType === 'avgStats') {
                                return match.id === instantMatch.id && instantMatch.status === 'Playing' &&
                                    match[statsType]?.every((item) => {
                                        if (item.name.includes(selectedTypeForSelect?.split('_')[0])) {
                                            return item.val >= parseFloat(selectedPercent);
                                        }
                                        // Returning true if not 'Corners PG' to continue the every function.
                                        return true;
                                    });
                            } else {
                                return match.id === instantMatch.id && instantMatch.status === 'Playing' &&
                                    match[statsType]?.every(item => Number(item.val.slice(0, -1)) >= Number(selectedPercent));
                            }
                        } else {
                            if (parseInt(selectedPercent) === 0) {
                                return match.id === instantMatch.id && instantMatch.status === 'Playing'
                            }
                        }
                    } else {
                        return match.id === instantMatch.id && instantMatch.status === 'Playing'
                    }
                });
            });
            matchesWithAllSelectedVal.forEach(match => {
                liveMatches.push({
                    leagueDetail: {leagueId, league, areaId, area},
                    match,
                });
            })
        });
    }

    let filteredLiveMatches = [];
    if (liveInstantMatches) {
        const liveInstant = liveInstantMatches.filter(
            item => item.status === 'Playing',
        );
        filteredLiveMatches = liveMatches.map(matches => {
            const findLiveInstant = liveInstant.find(
                match => match.id === matches.match.id,
            );
            return {
                ...matches,
                findLiveInstant,
            };
        });
    }

    const liveMatchesByLeagues = {};
    const liveLeagueDetails = {};
    filteredLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const lid = getLeagueIdentifier(leagueDetail.league, leagueDetail.leagueId);
        const matchAndLiveInstant = {...match, ...findLiveInstant};

        liveLeagueDetails[lid] = leagueDetail;

        const matches = liveMatchesByLeagues[lid] || [];
        liveMatchesByLeagues[lid] = [...matches, matchAndLiveInstant];
    });

    const liveSections = Object.entries(liveMatchesByLeagues).map(entry => {
        const [lid, matches] = entry;
        return {
            title: liveLeagueDetails[lid],
            data: matches,
        };
    })/*.sort(
        (a, b) => liveInstantLeaguesIndex[a.title.league] - liveInstantLeaguesIndex[b.title.league],
    );*/

    const liveMatchButton = (live) => {
        setLive(!live)
    }

    /*PAGE CODES*/
    const activeStatButton = (item) => {
        if (item.type === statsType) {
            return {
                background: 'var(--H3Dark1)',
                color: 'var(--H3Light0)',
            }
        } else {
            return {}
        }
    }

    /*PAGE CODES*/
    const activeMobileStatButton = (item, index) => {
        if (item.type === statsType) {
            return {
                background: 'var(--H3Light0)',
                color: 'var(--H3Dark3)',
                borderRadius: '10px',
            }
        } else {

        }
    }

    //Week Days Format
    const format = (day) => {
        return dayjs(day).format('ddd')
    }
    const formatDDDForMobile = (day) => {
        return dayjs(day).format('ddd')
    }

    const format2 = (day) => {
        return dayjs(day).format('DD')
    }

    const clickDays = async (day) => {
        await setCurrentDate(day)
    }

    const activeDayStyle = (day) => {
        if (currentDate === day.day) {
            return 'active-week-day'
        }
    }

    const generateStatsType = async (stat, header, toggle) => {

        const stats = [80, 70, 60, 50];
        const found = stats.includes(selectedPercent);

        let parts = selectedTypeForSelect.split(/(?<=\D)(?=\d)|(?<=\d)(?=\D)/);
        let type = parts[0];
        let number = parts[1];
        setStatsType(stat);
        setCurrentStats(header);

        if (stat === 'refereeStats') {
            await setSelectedTypeForSelect('All');
            await setStatsNameForSelect(lang === 'en' ? 'All' : 'Hepsi');
        } else {
            if (found) {
                switch (stat) {
                    case 'goalStats':
                        await setSelectedTypeForSelect(`goal${number}`);
                        break;
                    case 'cornerStats':
                        await setSelectedTypeForSelect(`corner${number}`);
                        break;
                    case 'cardStats':
                        await setSelectedTypeForSelect(`card${number}`);
                        break;
                }
            } else {
                switch (stat) {
                    case 'goalStats':
                        await setSelectedTypeForSelect(`All`);
                        await setStatsNameForSelect(i18String('All'));
                        break;
                    case 'cornerStats':
                        await setSelectedTypeForSelect(`All`);
                        await setStatsNameForSelect(i18String('All'));
                        break;
                    case 'cardStats':
                        await setSelectedTypeForSelect(`All`);
                        await setStatsNameForSelect(i18String('All'));
                        break;
                }
            }
        }
    }
    const handleChange = (event) => {
        if (!isPro) {
            navigate('/join-premium');
        } else {
            console.log('e.target.selectedOptions[0].getAttribute("name")', event.target.selectedOptions[0].getAttribute("name"));
            const eventValue = event.target.value;
            /*const parts = eventValue.split(" - ");
            const result = parts.length === 2 ? {type: parts[0], value: parts[1]} : null;*/

            setSelectedPercent(eventValue);
            setSelectedTypeForSelect(event.target.selectedOptions[0].getAttribute("name"));
        }
    };
    const disableSelect = () => {
        return statsType === 'refereeStats';
    }

    const daysFormatFunc = (date) => {
        const currentDate = new Date().toISOString().substr(0, 10);
        if (date.day === currentDate) {
            return (
                <div>
                    <div className='home-page-week-days-container'>
                        <span>{format2(date.day)}</span>
                        <span className='stats-date-day-name'>{i18String('Today')}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='home-page-week-days-container'>
                    <span>{format2(date.day)}</span>
                    <span className='stats-date-day-name'>{format(date.day)}</span>
                </div>
            )
        }
    }

    if (!fixturesData) {
        return (
            <div className=" const-width loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        {i18String('Loading')}
                    </div>
                </div>
            </div>
        )
    }


    const onCloseDialog = () => {
        setSelectDialog(false);
        if (!selectedSelection) {
            setSelectedSelection('');
        }
    }

    const handleClick = (type, valueType, header, value, name) => {
        console.log(`Name: ${name}, Type: ${valueType}, Value: ${value}, valueType: ${valueType}`);
        setSelectedPercent(Number(value));
        setStatsType(type);
        setCurrentStats(header);
        setSelectedTypeForSelect(valueType);
        setStatsNameForSelect(name);
        setSelectDialog(false);
    }

    return (
        <DocumentMeta {...homepageMeta[lang]}>
            <div className="const-width fixture-section">
                <div className="fixtures-general-container">
                    {/*FOR WEB*/}
                    <div className="fixtures-top-header">
                        <Dialog
                            open={selectDialog}
                            onClose={() => onCloseDialog()}
                            maxWidth="500"
                        >
                            <div className="select-stats-dialog">
                                <h2 style={{textAlign: 'center', margin: '10px 0 5px 0'}}>
                                    {i18String('All Stats')}
                                </h2>
                                <ul className='all-stats-ul-container'>
                                    {allPercentList.map(item => (
                                        <li key={item.type}>
                                            <strong>{item.name}</strong>
                                            <ul className='all-stats-ul-container'>
                                                {item.stats.map(stat => (
                                                    <li
                                                        key={stat.value.type}
                                                        onClick={() => handleClick(item.type, stat.value.type, item.header, stat.value.value, stat.name)}
                                                        className={(stat.value.type === selectedTypeForSelect && item.type === statsType) ? 'selected-stat-style stat-style' : 'stat-style'}
                                                    >
                                                        <span>
                                                            {stat.name}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Dialog>
                        <div className="live-status-container">
                            <div className="live-container" onClick={() => liveMatchButton(live)}>
                                {
                                    live ?
                                        <div className="live-match">
                                            <img className="live-icon" src={livePng} alt=""/>
                                            <span className="live-text">{i18String('Live')}</span>
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                        :
                                        <div className="not-live">
                                            <img className="not-live-icon" src={livePng} alt=""/>
                                            <span className="not-live-text">{i18String('Live')}</span>
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="stats-week-days-section">
                            {
                                week.map((day) => {
                                    return (
                                        <div key={day.day} className="week-days-container">
                                            <div onClick={() => clickDays(day.day)}
                                                 className={`${'dates'} ${activeDayStyle(day)}`}>
                                                {/*<span>{format2(day.day)}</span>
                                            <span className='stats-date-day-name'>{format(day.day)}</span>*/}
                                                {daysFormatFunc(day)}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='top-search-matches-container'>
                            <img className="search-icon" src={searchIcon} alt="search"/>

                            <input
                                type="search"
                                placeholder={i18String('Search Match')}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className='searchInput'
                            />
                        </div>

                        <div className='top-select-stats-list-container'>
                            <img className="filter-icon" src={filterIcon} alt="filter"/>
                            <Button class="filter-button"
                                    onClick={() => setSelectDialog(true)}>{statsNameForSelect}</Button>
                            {/*<select
                                className='stats-select-container'
                                value={selectedPercent}
                                onChange={handleChange}
                                disabled={disableSelect()}
                                onClick={() => setSelectDialog(true)}
                            >
                                {
                                    percentList[0][lang][statsType]?.map((item, index) => {
                                        return (
                                            <option
                                                key={item.value.type} name={item.value.type} value={item.value.value}>
                                                {item.name}
                                            </option>
                                        );
                                    })
                                }
                            </select>*/}
                        </div>

                        <div className="stats-button-container">
                            {
                                statsButtonsWeb.map((item) => {
                                    return (
                                        <div
                                            style={activeStatButton(item)}
                                            className="stat-button"
                                            onClick={() => generateStatsType(item.type, item.header, item.toggle)}
                                            key={item.toggle}
                                        >
                                            {item.text}
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                    {/*FOR MOBILE*/}
                    <div className="stats-top-header-mobile const-width-content">
                        <LinearComponent />

                        {
                            isShownSearch &&
                            <div className='top-search-matches-container'>
                                <img className="search-icon" src={searchIcon} alt="search"/>

                                <input
                                    type="search"
                                    placeholder={i18String('Search Match')}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className='searchInput'
                                />
                            </div>
                        }
                        {
                            isShownDates &&
                            <div className={'live-and-weekdays-container-mobile'}>
                                {/*<div className="live-status-container">
                                    <div className="live-container" onClick={() => liveMatchButton(live)}>
                                        {
                                            live ?
                                                <div className="live-match">
                                                    <img className="live-icon" src={livePng} alt=""/>
                                                    <span className="live-text">{i18String('Live')}</span>
                                                    <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                                </div>
                                                :
                                                <div className="not-live">
                                                    <img className="not-live-icon" src={livePng} alt=""/>
                                                    <span className="not-live-text">{i18String('Live')}</span>
                                                    <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                                </div>
                                        }
                                    </div>
                                </div>*/}
                                <div className="week-days-section">
                                    {
                                        week.map((day) => {
                                            return (
                                                <div key={day.day} className="week-days-container">
                                                    <div onClick={() => clickDays(day.day)}
                                                         className={`${'dates'} ${activeDayStyle(day)}`}>
                                                        <span>{format2(day.day)}</span>
                                                        <span>{formatDDDForMobile(day.day)}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        }


                        <div className="stats-mobile-button-container">
                            {
                                statsButtonsMobile.map((item, index) => {
                                    return (
                                        <div
                                            style={activeMobileStatButton(item, index)}
                                            className="stat-button-for-mobile"
                                            onClick={() => generateStatsType(item.type, item.header, item.toggle)}
                                            key={item.toggle}
                                        >
                                            {item.text}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/*FAV SECTIONS*/}
                    {
                        favSections && favMatches.length > 0 && favSections.map((league) => {
                            const lid = getLeagueIdentifier(league.title.league, league.title.leagueId);
                            const favMatches = favMatchesByLeagues[lid] || [];
                            const searchResultFavMatches = favMatches.filter(
                                (match) =>
                                    match.home.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    match.away.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            const matchesWithAllSelectedVal = searchResultFavMatches.filter(match => {
                                if (statsType !== 'refereeStats') {
                                    if (match[statsType]) {
                                        if (statsType === 'avgStats') {
                                            return match[statsType]?.every((item) => {
                                                if (item.name.includes(selectedTypeForSelect?.split('_')[0])) {
                                                    return item.val >= parseFloat(selectedPercent);
                                                }
                                                // Returning true if not 'Corners PG' to continue the every function.
                                                return true;
                                            });
                                        } else {
                                            if (match[statsType]) {
                                                return match[statsType]?.every((item) => Number(item.val.slice(0, -1)) >= Number(selectedPercent));
                                            }
                                        }
                                    } else {
                                        if (parseInt(selectedPercent) === 0) {
                                            return match
                                        }
                                    }
                                } else {
                                    return match
                                }
                            });

                            return (
                                favMatches.length === 0 ? null :
                                    <LeagueAndMatchesTable key={`${league.title.league}-${league.title.leagueId}`}
                                                           favMatches={matchesWithAllSelectedVal}
                                                           statsType={statsType}
                                                           currentStats={currentStats} league={league}
                                                           matches={matchesWithAllSelectedVal}
                                                           selectedTypeForSelect={selectedTypeForSelect}
                                                           searchTerm={searchTerm}
                                    />
                            )
                        })
                    }
                    {/*STATS SECTIONS*/}
                    {
                        sections && !live && sections.map((league, index) => {
                            const lid = getLeagueIdentifier(league.title.league, league.title.leagueId);
                            const matches = matchesByLeagues[lid] || [];
                            const searchResultMatches = matches
                                .filter(
                                    (match) =>
                                        match.home.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        match.away.toLowerCase().includes(searchTerm.toLowerCase())
                                );
                            return (
                                searchResultMatches.length === 0 ? null :
                                    <LeagueAndMatchesTable key={`${league.title.league}-${league.title.leagueId}`}
                                                           statsType={statsType}
                                                           index={index}
                                                           currentStats={currentStats}
                                                           live={live}
                                                           league={league}
                                                           matches={searchResultMatches}
                                                           selectedTypeForSelect={selectedTypeForSelect}
                                                           searchTerm={searchTerm}
                                    />
                            )
                        })
                    }
                    {/*LIVE SECTIONS*/}
                    {
                        liveSections.length !== 0 && live && liveSections.map((league, index) => {
                            const lid = getLeagueIdentifier(league.title.league, league.title.leagueId);
                            const liveMatches = liveMatchesByLeagues[lid] || [];
                            const searchResultLiveMatches = liveMatches.filter(
                                (match) =>
                                    match.home.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    match.away.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            return (
                                <div>
                                    {
                                        searchResultLiveMatches.length === 0 ? null :
                                            <LeagueAndMatchesTable key={`${league.title.league}-${league.title.leagueId}`}
                                                                   statsType={statsType}
                                                                   currentStats={currentStats}
                                                                   league={league}
                                                                   index={index}
                                                                   live={live}
                                                                   matches={searchResultLiveMatches}
                                                                   selectedTypeForSelect={selectedTypeForSelect}
                                                                   searchTerm={searchTerm}
                                            />
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        liveSections?.length === 0 && live &&
                        <div className={'league-name-container no-live-matches-container'}>
                            {
                                lang === 'tr' ?
                                    <span className={'no-live-matches-text'}>İstediğiniz filtreye uygun veya canlı maç yok!</span>
                                    :
                                    <span className={'no-live-matches-text'}>There are no live matches or match the filter you want!</span>
                            }
                        </div>
                    }
                    {
                        sections?.length === 0 &&
                        <div className={'league-name-container no-live-matches-container'}>
                            {
                                lang === 'tr' ?
                                    <span
                                        className={'no-live-matches-text'}>İstediğiniz filtreye uygun eşleşme yok!</span>
                                    :
                                    <span className={'no-live-matches-text'}>There are no matches suitable for the filter you want!</span>
                            }
                        </div>
                    }
                </div>
            </div>
        </DocumentMeta>
    );
}

export default Stats;
