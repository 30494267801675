import React, {useEffect, useState} from 'react';
import {
    fetchRefereeAllMatches,
    fetchRefereeAllStats,
    fetchRefereeSeasonMatches,
    fetchRefereeSeasonStats,
} from "../../../api";
import RefereeAllStats from "../../referee/RefereeAllStats";
import {Tab, Tabs} from "@mui/material";
import RefereeAllMatches from "../../referee/RefereeAllMatches";
import RefereeSeasonStats from "../../referee/RefereeSeasonStats";
import RefereeSeasonMatches from "../../referee/RefereeSeasonMatches";

function Referee(props) {
    const {matchDetailData, i18String} = props;

    const [refereeSeasonStatsData, setRefereeSeasonStatsData] = useState(null);
    const [refereeAllStatsData, setRefereeAllStatsData] = useState(null);
    const [refereeSeasonMatchesData, setRefereeSeasonMatchesData] = useState(null);
    const [refereeAllMatchesData, setRefereeAllMatchesData] = useState(null);
    const [tabToggle, setTabToggle] = useState(0);

    useEffect(() => {
        fetchRefereeSeasonStats(matchDetailData?.ref?.id, matchDetailData?.seasonId).then(data => {
            setRefereeSeasonStatsData(data.data.data);
        });
        fetchRefereeAllStats(matchDetailData?.ref?.id).then(data => {
            setRefereeAllStatsData(data.data.data);
        });

        fetchRefereeSeasonMatches(matchDetailData?.ref?.id, matchDetailData?.seasonId).then(data => {
            setRefereeSeasonMatchesData(data.data.data);
        });

        fetchRefereeAllMatches(matchDetailData?.ref?.id).then(data => {
            setRefereeAllMatchesData(data.data.data);
        });
    }, [matchDetailData]);
    console.log('refereeSeasonMatchesData', refereeSeasonMatchesData);

    const refereeTabs = [
        {
            name: `${i18String('Season Stats')}`, type: 'seasonStats', toggle: 0
        },
        {
            name: `${i18String('All Stats')}`, type: 'allStats', toggle: 1
        },
        {
            name: `${i18String('Season Matches')}`, type: 'seasonMatches', toggle: 2
        },
        {
            name: `${i18String('All Matches')}`, type: 'allMatches', toggle: 3
        }
    ]

    const clickTabs = (tab) => {
        setTabToggle(tab.toggle);
    }


    return (
        <div className='referee-section'>
            <div className='referee-name-title-row' style={{justifyContent: 'center'}}>
                <a href={`/referee/${matchDetailData?.ref?.id}/season/${matchDetailData?.seasonId}`}>
                    {matchDetailData?.ref?.name}
                </a>
            </div>


            <div className='match-details-player-stats-tabs-section' style={{borderBottom: '1px solid #dfdfdf'}}>
                <Tabs
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{style: {display: "none"}}}
                    value={tabToggle}
                >
                    {
                        refereeTabs.map((tabs, index) => {
                            return (
                                <Tab
                                    key={tabs.type}
                                    label={tabs.name}
                                    onClick={() => clickTabs(tabs)}
                                    sx={{ textTransform: "none" }}
                                    style={{
                                        color: tabToggle === index ? '#3e2d56' : '#485b74'
                                    }}
                                >
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>

            {
                tabToggle === 0 && <RefereeSeasonStats refereeSeasonStatsData={refereeSeasonStatsData}/>
            }

            {
                tabToggle === 1 && <RefereeAllStats refereeAllStatsData={refereeAllStatsData}/>
            }

            {
                tabToggle === 2 && <RefereeSeasonMatches refereeSeasonMatchesData={refereeSeasonMatchesData} i18String={i18String}/>
            }

            {
                tabToggle === 3 && <RefereeAllMatches refereeAllMatchesData={refereeAllMatchesData} i18String={i18String}/>
            }
        </div>
    );
}

export default Referee;
