import React, {useEffect} from 'react';
import './AppPlatformDirect.css';

function AppPlatformDirect(props) {

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        console.log('userAgent', userAgent);

        if (/android/i.test(userAgent)) {
            window.location.href = '/android';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://apps.apple.com/tr/app/stats-365-canl%C4%B1-ma%C3%A7-sonu%C3%A7lar%C4%B1/id1592722868?l=tr';
        } else {
            window.location.href = '/landing';
        }
    }, []);

    return (
        <div className='app-platform-direct-section'>
            <div>
                <span className='app-direct-text'>Loading...</span>
            </div>
        </div>
    );
}

export default AppPlatformDirect;
