export const saveMatchToLocalStorage = (key, data) => {
    const expirationTime = new Date().getTime() + (24 * 60 * 60 * 1000); // 1 gün (24 saat) sonra geçerlilik bitiyor

    const item = {
        data: data,
        expiration: expirationTime
    };
    localStorage.setItem(key, JSON.stringify(item));
    window.open(`/match-detail/${data.matchId}`);
};

export const getMatchFromLocalStorage = (key) => {
    const item = JSON.parse(localStorage.getItem(key));

    if (!item) {
        window.location.href = '/';
        return null;
    }

    const currentTime = new Date().getTime();
    if (currentTime > item.expiration) {
        window.location.href = '/';
        localStorage.removeItem(key);
        return null;
    }

    return item.data;
};
