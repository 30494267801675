import React, {useContext} from 'react';
import TableContainer from "@mui/material/TableContainer";
import {Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {saveMatchToLocalStorage} from "../../../local-storage/ManageLocalStorage";
import {AppContext} from "../../../../context/AppContext";

function GoalsMpTable(props) {
    const {i18String, isPro} = useContext(AppContext);

    const {filteredMatches, matchScoreStyle, date} = props;

    const headCells = [
        { id: 'date', name: `${i18String('Date and League')}`, label: `${i18String('Date and League')}`, align: 'left', visible: 'onlyMobile', width:  90},
        /*{ id: 'tournament', label: 'Tournament', align: 'center', visible: 'onlyMobile', width:  50},*/
        { id: 'round', name: `${i18String('Round')}`, label: `${i18String('mp-R')}`,  align: 'center', visible: 'all', width: 40 },
        { id: 'home', name: ``, label: '', align: 'center', visible: 'all', width:  110},
        { id: 'match', name: `${i18String('Match')}`, label: `${i18String('Match')}`, align: 'center', colSpan: 2, visible: 'all', width:  12},
        { id: 'away', name: ``, label: '', align: 'center', visible: 'all', width:  110},
    ];

    return (
        <div>
            {/* Filtrelenmiş Maçları Listeleme */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow className='mp-table-header-row-container'>
                            {headCells.filter((head) => head.visible === 'all' || (head.visible === 'onlyMobile' && window.innerWidth > 768)) // Masaüstünde 'desktop' olanları göster
                                .map((head) =>
                                <TableCell
                                    key={head.id}
                                    align={head.align || 'center'}
                                    colSpan={head?.colSpan} sx={{ maxWidth: head.width, minWidth: head.width }}
                                    title={head.name}
                                >
                                    {head.label}
                                </TableCell>
                            )}

                        </TableRow>
                    </TableHead>
                    <TableBody className='mp-table-body-container'>
                        {filteredMatches?.map((match, index) => (
                            <TableRow
                                key={match.id || index}
                                className='mp-matches-row-section'
                            >
                                <TableCell sx={{ maxWidth: 90 }} align="left" className='mp-table-td only-web'>
                                    <div style={{display: 'grid'}}>
                                        <span  className='mp-match-date'>{date(match.date)}</span>
                                        <span title={match.league} className='mp-match-league'>{match.league}</span>
                                    </div>
                                </TableCell>
                                {/*<TableCell sx={{ maxWidth: 50 }} align="center" className='mp-table-td only-web' title={match.league}>
                                    {match.league}
                                </TableCell>*/}
                                <TableCell sx={{ width: 20 }} align="center" className='mp-table-td'>{match.round}</TableCell>
                                <TableCell sx={{ minWidth: 140, maxWidth: 140}} align="right" className='mp-table-td'>
                                    <a
                                        href={`/team-detail/${match.homeId}`}
                                        target="_blank"
                                        className="mp-team-name"
                                        title={match.home}
                                    >
                                        {match.home}
                                    </a>
                                </TableCell>
                                <TableCell
                                    onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                        matchId: match.id,
                                        home: match.home,
                                        homeId: match.homeId,
                                        away: match.away,
                                        awayId: match.awayId,
                                        time: match.dateTimeUtc,
                                        seasonId: match.seasonId
                                    })}
                                    sx={{ width: 15 }} align="center" className={`mp-table-td ${matchScoreStyle(match)}`}>
                                    <a
                                        href={`/match-detail/${match.id}`}
                                        target="_blank"
                                        className='mp-match-score'
                                    >
                                        {match.hscore}
                                    </a>
                                </TableCell>
                                <TableCell
                                    onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                        matchId: match.id,
                                        home: match.home,
                                        homeId: match.homeId,
                                        away: match.away,
                                        awayId: match.awayId,
                                        time: match.dateTimeUtc,
                                        seasonId: match.seasonId
                                    })}
                                    sx={{ width: 15 }} align="center" className={`mp-table-td ${matchScoreStyle(match)}`}>
                                    <a
                                        href={`/match-detail/${match.id}`}
                                        target="_blank"
                                        className='mp-match-score'
                                    >
                                        {match.ascore}
                                    </a>
                                </TableCell>
                                <TableCell sx={{ minWidth: 140, maxWidth: 140}} align="left" className='mp-table-td'>
                                    <a
                                        href={`/team-detail/${match.awayId}`}
                                        target="_blank"
                                        className="mp-team-name"
                                        title={match.away}
                                    >
                                        {match.away}
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default GoalsMpTable;
