import React, {useContext} from 'react';
import {slide as Menu} from "react-burger-menu";
import img from "../../../assets/icons/instagram.png";
import logo from "../../../assets/icons/logo.png";
import userImg from "../../../assets/icons/user.png";
import Button from "@mui/material/Button";
import {languageLists} from "../Languages";
import {Switch} from "@mui/material";
import onLightMode from "../../../assets/icons/onLightMode.png";
import offLightMode from "../../../assets/icons/offLightMode.png";
import onDarkMode from "../../../assets/icons/onDarkMode.png";
import offDarkMode from "../../../assets/icons/offDarkMode.png";
import {AppContext} from "../../../context/AppContext";
import {Link} from "react-router-dom";

function MobileHamburgerMenu(props) {
    const {
        logOut,
        setLanguage,
        i18String,
        changeTheme,
        siteTheme,
    } = useContext(AppContext);

    const userToken = localStorage.getItem('user-token');
    const username = localStorage.getItem('username');

    const activeLanguage = (lan) => {
        if (localStorage.getItem('language') === lan) {
            return {
                width: '24px',
                height: '24px'
            }
        }
    }

    return (
        <div className={'hamburger-menu-section'}>
            <Menu>
                <a href={'/'} className="burger-menu-site-logo-text">
                    <img className={'logo'} src={logo} alt="user"/>
                </a>
                <div className='authentication-section'>
                    {
                        userToken ?
                            <div className={'user-info-container'}>
                                <div className={'user-detail-container burger-menu-content'}>
                                    <img className={'user-icon'} src={userImg} alt="user"/>
                                    <div className={'username-text'}>{username}</div>
                                </div>
                                <div className={'user-detail-container burger-menu-content'}>
                                    <Link className={'username-text'}
                                          to={`/account-detail/${username}`}>{i18String('Profile')}</Link>
                                </div>
                                <div
                                    className={'mobile-exit-button-container burger-menu-content'}
                                    style={{alignItems: 'self-start'}}>
                                    <Button variant="text" onClick={logOut}><span
                                        className={'mobile-exit-button'}>
                                                        <span>{i18String('Logout')}</span>
                                                    </span></Button>
                                </div>
                            </div>
                            :
                            <div className={'mobile-auth-burger-menu-container'}>
                                <a id="home" className="burger-menu-item burger-menu-content"
                                   href="/sign-in">{i18String('Login')}</a>
                                <a id="about" className="burger-menu-item burger-menu-content"
                                   href="/sign-up">{i18String('Register')}</a>
                            </div>
                    }
                </div>

                <div className='flags-for-language-section-mobile'>
                    {languageLists?.map(item => (
                        <img style={activeLanguage(item.value)}
                             onClick={() => setLanguage(item.value)}
                             className='language-flags-mobile' src={item.imgSource}
                             alt="Türkiye"/>
                    ))}
                </div>

                {/*<div className='switch-and-theme-icons-container'>
                    <Switch
                        checked={siteTheme === 'dark'}
                        name="theme"
                        id="theme"
                        onChange={() => changeTheme()}
                        color='warning'
                        size={'small'}
                    />

                    <div className='theme-mode-icons-container'>
                        {
                            siteTheme === 'light' ?
                                <img className='theme-mode-icons' src={onLightMode}
                                     alt={'theme'}/>
                                :
                                <img className='theme-mode-icons' src={offLightMode}
                                     alt={'theme'}/>
                        }

                        {
                            siteTheme === 'dark' ?
                                <img className='theme-mode-icons' src={onDarkMode}
                                     alt={'theme'}/>
                                :
                                <img className='theme-mode-icons' src={offDarkMode}
                                     alt={'theme'}/>
                        }
                    </div>
                </div>*/}
            </Menu>
        </div>
    );
}

export default MobileHamburgerMenu;
