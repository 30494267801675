import React, {useContext, useEffect, useState} from 'react';
import {fetchMatchDetail, fetchPlayerStats} from "../../../api";
import HomePlayerStatsTable from "./HomePlayerStatsTable";
import AwayPlayerStatsTable from "./AwayPlayerStatsTable";
import {Tab, Tabs} from "@mui/material";
import {AppContext} from "../../../context/AppContext";
import {useParams} from "react-router-dom";

function PlayerStats(props) {
    const {i18String} = useContext(AppContext);
    const params = useParams();

    const {matchTeamsDetail} = props;
    const [playerStatsData, setPlayerStatsData] = useState(null);
    const [tabType, setTabType] = useState('Goals');
    const [tabToggle, setTabToggle] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [homeStats, awayStats] = await Promise.all([
                    fetchPlayerStats(matchTeamsDetail?.seasonId, matchTeamsDetail?.homeId, tabType),
                    fetchPlayerStats(matchTeamsDetail?.seasonId, matchTeamsDetail?.awayId, tabType)
                ]);

                setPlayerStatsData({
                    home: homeStats.data.data,
                    away: awayStats.data.data
                });
            } catch (error) {
                console.error('Error fetching player stats:', error);
            }
        };

        fetchData();
    }, [matchTeamsDetail?.seasonId, matchTeamsDetail?.homeId, matchTeamsDetail?.awayId, tabType]);

    const statsButtons = [
        {text: `${i18String('P-Goals')}`, type: 'Goals', toggle: 0},
        {text: `${i18String('P-Assists')}`, type: 'Assists', toggle: 1},
        {text: `${i18String('P-Cards')}`, type: 'Cards', toggle: 2},
        {text: `${i18String('P-Shots')}`, type: 'Shots', toggle: 3},
        {text: `${i18String('P-Shots On Target')}`, type: 'ShotsOnTarget', toggle: 4},
        {text: `${i18String('P-Fouls')}`, type: 'Fouls', toggle: 5},
        {text: `${i18String('P-Fouls Won')}`, type: 'FoulsWon', toggle: 6},
        {text: `${i18String('P-Tackles')}`, type: 'Tackles', toggle: 7},
        {text: `${i18String('P-Offsides')}`, type: 'Offsides', toggle: 8},
    ]

    const clickTabs = (tab) => {
        setTabType(tab.type);
        setTabToggle(tab.toggle);
    }

    return (
        <div>
            <div className={params.tabType === 'player-stats' ? 'match-details-player-stats-tabs-section-full-width' : 'match-details-player-stats-tabs-section'}>
                <Tabs
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{style: {display: "none"}}}
                    value={tabToggle}
                >
                    {
                        statsButtons.map((tabs, index) => {
                            return (
                                <Tab
                                    key={tabs.type}
                                    className={'best-teams-tab'}
                                    label={tabs.text}
                                     onClick={() => clickTabs(tabs)}
                                    sx={{ textTransform: "none" }}
                                    style={{
                                        color: tabToggle === index ? '#3e2d56' : '#485b74' // Aktif sekme siyah, diğerleri varsayılan
                                    }}
                                >
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>

            <HomePlayerStatsTable tabType={tabType} matchTeamsDetail={matchTeamsDetail} playerStatsData={playerStatsData}/>
            <AwayPlayerStatsTable tabType={tabType} matchTeamsDetail={matchTeamsDetail} playerStatsData={playerStatsData}/>
        </div>
    );
}

export default PlayerStats;
