import {formatStatsAndType} from "./FormatStatsAndType";


export const homepageMeta = {
    en: {
        title: 'Football / Soccer Stats, Live Scores, Form Tables & Results - Stats365.io',
        description: 'Detailed football stats and live scores for the Premier League and other 2000+ leagues in the world such as the UEFA Champions League, Serie A, La Liga, Ligue 1, MLS and Copa Libertadores.',
        canonical: 'https://www.stats365.io',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'football, football statistics, football scores, football results, football tables, football fixtures, football stats, football teams, football players, football news, football blog, football previews, football formations, football tactics, soccer, soccer statistics, soccer results, soccer tables, soccer fixtures, soccer stats, soccer teams, soccer players, soccer news, soccer blog, soccer previews, soccer formations, soccer tactics, player ratings, goal stats, corner stats, card stats, player stats, referee stats'
            }
        }
    },
    tr: {
        title: 'Futbol İstatistikleri, Maç Sonuçları, Canlı Skor & İddaa Sonuçları - Stats365.io',
        description: "2000'den fazla futbol ligi için detaylı futbol istatistikleri. Türkiye Süper Lig puan durumu, canlı skor ve sonuçlar, fikstür, maç sonuçları, iddaa oranları, istatistikler, alt-üst oranları",
        canonical: 'https://www.stats365.io',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Canlı Sonuçlar, İddaa Sonuçları, İddaa, canlı maç sonuçları, iddaa canlı skor, iddaa maç skorları, iddaa programı sonucları, Futbol, Futbol İstatistikleri, Tablolar,Tahminler, Alt / Üst, Korner, Gol İstatistikleri, Korner İstatistikleri, Kart İstatistikleri, Maç İstatistikleri, Sonuçlar, İY/MS, Goller, Gol Kralları, Canlı maç istatistikleri'
            }
        }
    }
}

export const scoreRadarMeta = {
    en: {
        title: 'In-Play Football / Soccer Stats | Goals, Corners, Cards, Shots, Fauls - Stats365.io',
        description: 'View Live Football Match Stats in a unique and digestable format. Stats365 covers football stats such as goals, corners, cards, shots, shots on target (SoT), fouls, possession and more.',
        canonical: 'https://www.stats365.io/scoreradar',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'goal over predictions, Corner Over Predictions, Corner Under Predictions, 1X2 Predictions, No Goals in First 10 minutes Predictions, Double Chance Predictions, Goal Over 1.5 Predictions, Complete soccer stats'
            }
        }
    },
    tr: {
        title: 'Canlı Maç İstatistikleri, Canlı Skor, Maç Sonuçları & İddaa Tahminleri  - Stats365.io',
        description: 'ScoreRadar canlı maç istatistikleri özelliği ile maçları gol, korner, kart, şut ve faul istatistikleri ile tek ekranda takip edebilirsiniz.',
        canonical: 'https://www.stats365.io/scoreradar',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Canlı Maç Sonuçları,Canlı Skor,İddaa Sonuçları,Canli iddaa,Fikstürler ve Sonuçlar,İddaa Canlı Maç Sonuçları,Canlı Maç İstatistikleri'
            }
        }
    }
}


export const tvGuideMeta = {
    en: {
        title: "Watch Football / Soccer on TV | Today's LIVE Football on TV Listings - Stats365.io",
        description: 'Your complete Live Football / Soccer on TV Guide, including Premier League, UEFA Champions League, Serie A, La Liga, Ligue 1, MLS and Copa Libertadores and more.',
        canonical: 'https://www.stats365.io/tv',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Live Football On TV, Live Football TV,Sky Sports, TNT Sports, Premier Sports, BBC, ITV,Live Football on TV Schedules'
            }
        }
    },
    tr: {
        title: "Maç Hangi Kanalda | TV'de Canlı Futbol Yayınları - Stats365.io",
        description: "Canlı futbol maçları ne zaman, hangi kanalda? Stats365 ile maçlar hangi kanalda hemen öğrenin ve hiçbir maçı kaçırmayın.",
        canonical: 'https://www.stats365.io/tv',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: "Canlı futbol, Canlı izle, maç hangi kanal,Spor Ekranı,TV'de Canlı Spor,Maç Hangi Kanalda,TV'de Yayın Ekranı,Bugünkü Futbol maçları,Bu Akşam Hangi Maçlar Var, Hangi Kanalda?"
            }
        }
    }
}

















export const liveScoresMeta = {
    tr: {
        title: 'Stats365 - Futbol Canlı Sonuçlar | İddaa Sonuçları | Canlı Bahis | Stats365.com',
        description: 'İddaa, canlı maç sonuçları, iddaa canlı skor, iddaa maç skorları, iddaa programı sonucları.',
        canonical: 'https://www.stats365.io/',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'iddaa, Futbol, Tahmin, Maçlar, Spor, Sahadan, Canlı Sonuçlar, Basketbol, Fenerbahçe, Galatasaray, Beşiktaş, Trabzonspor, Superlig, Transfer, Transferler'
            }
        }
    },
    en: {
        title: 'Stats365 - Football Live Scores, Latest Football Results | Stats365.com',
        description: 'Football live scores page on Stats365.com offers all the latest football results from more than 2200+ football leagues all around the world including EPL, LaLiga, Serie A, Bundesliga, UEFA Champions League and more.',
        canonical: 'https://www.stats365.io/',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Football, livescore, live scores, results'
            }
        }
    }
}

export const bestTeamsMeta = (stats, type) => {
    return {
        tr: {
            title: `Stats365 - 1500'den fazla lig için ${formatStatsAndType(type)} Oranından Fazla ${formatStatsAndType(stats)} İstatistikleri. En yüksek ve en düşük ${formatStatsAndType(stats)} yüzdesi ${formatStatsAndType(type)} olan Takımların, Liglerin ve Maçların listesi.`,
            description: 'Dünyanın en kapsamlı Futbol İstatistikleri / Futbol İstatistikleri sitesi. Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi ve daha fazlası. Dünya genelinde 1000\'den fazla futbol müsabakasını kapsıyoruz.',
            canonical: 'https://www.stats365.io/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi'
                }
            }
        },
        en: {
            title: `Stats365 - ${formatStatsAndType(type)} ${formatStatsAndType(stats)} Statistics for 1500+ leagues. List of Teams, Leagues, and Matches with the highest and lowest ${formatStatsAndType(type)} ${formatStatsAndType(stats)} %.`,
            description: 'World\'s most in-depth Football Stats / Soccer Stats site. Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play & more. We cover 1000+ soccer competitions across the globe',
            canonical: 'https://www.stats365.io/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play'
                }
            }
        }
    }
}

export const trendMatchesMeta = (stats, type) => {
    return {
        tr: {
            title: `Stats365 - 1500'den fazla lig için ${formatStatsAndType(type)} Oranından Fazla ${formatStatsAndType(stats)} İstatistikleri. En yüksek ve en düşük ${formatStatsAndType(stats)} yüzdesi ${formatStatsAndType(type)} olan Takımların, Liglerin ve Maçların listesi.`,
            description: 'Dünyanın en kapsamlı Futbol İstatistikleri / Futbol İstatistikleri sitesi. Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi ve daha fazlası. Dünya genelinde 1000\'den fazla futbol müsabakasını kapsıyoruz.',
            canonical: 'https://www.stats365.io/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi'
                }
            }
        },
        en: {
            title: `Stats365 - ${formatStatsAndType(type)} ${formatStatsAndType(stats)} Statistics for 1500+ leagues. List of Teams, Leagues, and Matches with the highest and lowest ${formatStatsAndType(type)} ${formatStatsAndType(stats)} %.`,
            description: 'World\'s most in-depth Football Stats / Soccer Stats site. Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play & more. We cover 1000+ soccer competitions across the globe',
            canonical: 'https://www.stats365.io/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play'
                }
            }
        }
    }
}

export const teamDetailsMeta = (teamName, teamId) => {
    return {
        tr: {
            title: `Stats365 - ${teamName} hakkında tüm bilgi`,
            description: `Stats365 - ${teamName} hakkında tüm bilgi Descriptions`,
            canonical: `https://www.stats365.io/team-detail/${teamId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        en: {
            title: `Stats365 - All information about ${teamName}`,
            description: `Stats365 - All information about ${teamName} Descriptions`,
            canonical: `https://www.stats365.io/team-detail/${teamId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        }
    }
}

export const matchDetailsMeta = (matchDetail) => {
    return {
        tr: {
            title: `Stats365 - ${matchDetail?.home} - ${matchDetail?.away} maçı detayları`,
            description: `Stats365 - ${matchDetail?.home} - ${matchDetail?.away} maçı detayları Descriptions`,
            canonical: `https://www.stats365.io/match-detail/${matchDetail?.id}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        en: {
            title: `Stats365 - ${matchDetail?.home} - ${matchDetail?.away} match details`,
            description: `Stats365 - ${matchDetail?.home} - ${matchDetail?.away} match details Descriptions`,
            canonical: `https://www.stats365.io/match-detail/${matchDetail?.id}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        }
    }
}

export const leagueDetailsMeta = (league) => {
    return {
        tr: {
            title: `Stats365 - ${league?.league} hakkında tüm bilgi`,
            description: `Stats365 - ${league?.league} hakkında tüm bilgi Descriptions`,
            canonical: `https://www.stats365.io/league-details/${league?.seasonId}/${league.leagueId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        en: {
            title: `Stats365 - All information about ${league?.league}`,
            description: `Stats365 - All information about ${league?.league} Descriptions`,
            canonical: `https://www.stats365.io/league-details/${league?.seasonId}/${league.leagueId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        }
    }
}
