import React, {useContext, useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import './Payment.css'
import checkImg from '../../assets/icons/check.png'
import waitImg from '../../assets/icons/waiting.png'
import {AppContext} from "../../context/AppContext";

function PaymentSuccess(props) {

    const {i18String} = useContext(AppContext);
    const userToken = localStorage.getItem('user-token');
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams()

    const sessionId = searchParams.get('session_id');
    const userId = searchParams.get('userId');

    /*FOR CLIENT TYPE*/
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile/i.test(userAgent);
    const clientType = isMobile ? 'WEB_MOBILE' : 'WEB';

    const language = localStorage.getItem('language' || 'en');

    useEffect(() => {
        const requestOptionsSuccessWithoutToken = {
            method: 'POST',
            headers: {'x-platform': 'Stats365', 'x-client-type': clientType, 'x-language': language},
        };
        const requestOptionsSuccess = {
            method: 'POST',
            headers: {'Authorization': `Bearer ${userToken}`, 'x-platform': 'Stats365', 'x-client-type': clientType, 'x-language': language},
        };

        const paymentSuccess = async () => {
            if (!userToken) {
                await fetch(`https://santra24.6stats.com/api/v1/payment/finalize-payment/userId?session_id=${sessionId}&userId=${userId}`, requestOptionsSuccessWithoutToken)
                    .then(async (response) => {
                        const responseObject = await response.json();
                        console.log('success Payment', responseObject);
                        if (responseObject) {
                            setLoading(false);
                            setInterval(() => {
                                window.location.href = '/sign-in'
                            }, 2500);
                        }
                    })
                    .catch((err) => err);
            } else {
                await fetch(`https://santra24.6stats.com/api/v1/payment/finalize-payment?session_id=${sessionId}`, requestOptionsSuccess)
                    .then(async (response) => {
                        const responseObject = await response.json();
                        console.log('success Payment', responseObject);
                        if (responseObject) {
                            localStorage.removeItem('user');
                            setLoading(false);

                            const detailPayload = {
                                method: 'GET',
                                headers: {'Authorization': `Bearer ${userToken}`},
                            }
                            const userDetail = await fetch('https://santra24.6stats.com/api/user/me', detailPayload);
                            const user = await userDetail.json();
                            console.log('success user me ', user)
                            if (user.success === true) {
                                localStorage.setItem('user', JSON.stringify(user.data));
                                setInterval(() => {
                                    window.location.href = '/'
                                }, 2500)
                            }
                        }
                    })
                    .catch((err) => err);
            }
        }

        paymentSuccess().then(r => r);

    }, [])

    return (
        <div className='const-width'>
            <div className='payment-section'>
                {
                    loading ?
                        <div className='wait-container'>
                            <img className='success-check-icon' src={waitImg} alt="Success"/>
                            <span className='success-text'>{i18String('Please Wait...')}</span>
                        </div>
                        :
                        <div className='success-message-container'>
                            <div className='success-text-and-img-container'>
                                <img className='success-check-icon' src={checkImg} alt="Success"/>
                                <span className='success-text'>{i18String('Congratulations, your PRO membership has been successfully completed.')}</span>
                            </div>

                            <div className='go-home-page-button-container'>
                                <Link className='go-home-page-button' to={'/'}>{i18String('Go Home Page')}</Link>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default PaymentSuccess;
