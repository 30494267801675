import React from 'react';

function RefereeAllStats(props) {
    const  {refereeAllStatsData} = props;

    return (
        <div className='referee-stats-general-section'>
            {
                refereeAllStatsData && refereeAllStatsData.map(data => {
                    return (
                        <div style={{width: '100%'}} key={data.section}>
                            <div className='referee-stats-section-row'>
                                {data.section}
                            </div>
                            {
                                data.stats.map(stat => {
                                    return(
                                        <div key={stat.name} className='referee-stat-row-container'>
                                            <span className='referee-info-text'>{stat.name}</span>
                                            <span className='referee-info-text'>{stat.valStr}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default RefereeAllStats;
