import React, {useContext, useEffect, useState} from 'react';
import {Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import downArrow from '../../../assets/icons/down-arrow.png';
import {colorTheme} from "../../ColorCss";
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";
import {AppContext} from "../../../context/AppContext";

function LeaguePlayerStatsTable(props) {
    const {i18String} = useContext(AppContext);
    const {tabType, league, playerStatsData} = props;

    const [statsTableData, setStatsTableData] = useState(playerStatsData || []);
    const [order, setOrder] = React.useState('asc');
    const [tableCellId, setTableSetId] = useState();

    useEffect(() => {
        if (playerStatsData) {
            setStatsTableData(playerStatsData);
        }
    }, [playerStatsData]);

    const headCells = [
        { id: 'standing', label: '#', align: 'center' },
        { id: 'team', label: `${i18String('Team')}`, align: 'left' },
        { id: 'playerName', label: `${i18String('P-Players')}`, align: 'left' },
        { id: 'apps', label: `${i18String('P-Apps')}`, align: 'center' },
        { id: 'mins', label: `${i18String('P-Mins')}`, align: 'center' },
        { id: 'c1', label: `${i18String('1')}`, align: 'center' },
        { id: 'c2', label: `${i18String('2')}`, align: 'center' },
        { id: 'c3p', label: `${i18String('3+')}`, align: 'center' },
        { id: 'per90', label: `${i18String('Per 90 Mins')}`, align: 'center' },
        { id: 'avg', label: `${i18String('Avg Per Game')}`, align: 'center' },
        { id: 'total', label: `${i18String('Total')}`, align: 'center' },
        { id: 'yellows', label: `${i18String('YC')}`, align: 'center' },
        { id: 'yellowsP90', label: `${i18String('YC / P90')}`, align: 'center' },
        { id: 'yellowsAvg', label: `${i18String('YC Avg')}`, align: 'center' },
        { id: 'reds', label: `${i18String('RC')}`, align: 'center' },
        { id: 'redsP90', label: `${i18String('RC / P90')}`, align: 'center' },
        { id: 'redsAvg', label: `${i18String('RC Avg')}`, align: 'center' },
        { id: 'fouls', label: `${i18String('P-Fouls')}`, align: 'center' },
        { id: 'foulsP90', label: `${i18String('Fouls / P90')}`, align: 'center' },
        { id: 'foulsAvg', label: `${i18String('Fouls Avg')}`, align: 'center' },
    ];

    const handleRequestSort = (property) => {
        if (order === 'asc') {
            const sorted = [...statsTableData].sort((a, b) => a[property] > b[property] ? 1 : -1)
            setOrder('desc');
            setStatsTableData(sorted);
            setTableSetId(property);
        }

        if (order === 'desc') {
            const sorted = [...statsTableData].sort((a, b) => a[property] < b[property] ? 1 : -1)
            setOrder('asc');
            setStatsTableData(sorted);
            setTableSetId(property);
        }
    };

    const selectedTableHeaderCell = (cellId) => {
        if (cellId === tableCellId) {
            return {
                background: colorTheme.selectedTableStatsHeader,
                color: colorTheme.selectedTableStats,
                disabled: 'flex',
                placeItems: 'center'
            }
        }
    }

    const selectedArrowIcon = (cellId) => {
        if (cellId === tableCellId) {
            if (order === 'asc') {
                return <img className='down-arrow for-asc-arrow' src={downArrow} alt=""/>
            } else {
                return <img className='down-arrow' src={downArrow} alt=""/>
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const cardTypeSections = ['standing', 'playerName', 'apps', 'mins', 'yellows', 'yellowsP90', 'yellowsAvg', 'reds', 'redsP90', 'redsAvg', 'fouls','foulsP90', 'foulsAvg'];

    return (
        <div className={'league-stats-general-table-section'}>
            <div className={'league-stats-table-title-container'}>
                <img className="league-player-stats-team-logo"
                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league?.areaId}.png`}
                     onError={(e) => onImageError(e)}
                     alt={league?.areaId}/>
                <span className={'league-stats-table-title'}>{league?.area} {league?.league}</span>
            </div>

            {/*HOME TABLE*/}
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow className={'stats-table-header-row-container'}>
                            {
                                headCells
                                    .filter(cell => {
                                        // type "cards" olduğunda kartlarla ilgili olanları göster
                                        if (tabType === 'Cards') {
                                            return cardTypeSections.includes(cell.id);
                                        }
                                        // type başka bir şey olduğunda kartlarla ilgili sütunlar haricindekileri göster
                                        return !['yellows', 'yellowsAvg', 'yellowsP90', 'reds', 'redsAvg', 'redsP90', 'fouls', 'foulsAvg', 'foulsP90'].includes(cell.id);
                                    })
                                    .map((head, index) => {
                                    return (
                                        <TableCell
                                            key={head.id}
                                            onClick={() => handleRequestSort(head.id)}
                                            align={head.align}
                                            className={'stats-table-header-cell'}
                                            style={selectedTableHeaderCell(head.id)}
                                        >
                                            <div className='player-stats-table-header-icon-and-title'>
                                                {selectedArrowIcon(head.id)}
                                                {head.label}
                                            </div>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'asd'}>
                        {
                            statsTableData &&
                            statsTableData.map((row, i) => (
                                <TableRow key={row.playerId} className={'league-stats-table-row'}>
                                    {
                                        tabType !== 'Cards' ? (
                                            <>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    <span className={'standings-text'}>{i + 1}</span>
                                                </TableCell>
                                                <TableCell scope="row" className={'league-stats-table-td'}>
                                                    <div className={'league-stats-team-and-logo-container'}>
                                                        <img className="league-stats-teams-logo"
                                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${row.teamId}.png`}
                                                             onError={(e) => onImageError(e)}
                                                             alt={row.teamName}/>
                                                        <a target={"_blank"} href={`/team-detail/${row.teamId}`}
                                                           className={'league-stats-team-name'} style={{color: 'var(--H3Text1)'}}>{row.teamName}</a>
                                                    </div>
                                                </TableCell>
                                                <TableCell scope="row" className={'league-stats-table-td'}>
                                                    <div className={'league-stats-team-and-logo-container'}>
                                                        <a target={"_blank"} className={'league-stats-team-name'}>
                                                            {row.playerName}
                                                        </a>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.apps ? <span>{row.apps}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.mins ? <span>{row.mins}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.c1 ? <span>{row.c1}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.c2 ? <span>{row.c2}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.c3p ? <span>{row.c3p}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td-Avg'} align="center">
                                                    {row.per90 ? <span>{row.per90}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.avg ? <span>{row.avg}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td-Avg'} align="center">
                                                    {row.total ? <span>{row.total}</span> : <span>0</span>}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    <span className={'standings-text'}>{i + 1}</span>
                                                </TableCell>
                                                <TableCell scope="row" className={'league-stats-table-td'}>
                                                    <div className={'league-stats-team-and-logo-container'}>
                                                        <img className="league-stats-teams-logo"
                                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${row.teamId}.png`}
                                                             onError={(e) => onImageError(e)}
                                                             alt={row.teamName}/>
                                                        <a target={"_blank"} className={'league-stats-team-name'}>
                                                            {row.playerName}
                                                        </a>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.apps ? <span>{row.apps}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.mins ? <span>{row.mins}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.yellows ? <span>{row.yellows}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.yellowsP90 ? <span>{row.yellowsP90}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.yellowsAvg ? <span>{row.yellowsAvg}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.reds ? <span>{row.reds}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.redsP90 ? <span>{row.redsP90}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.redsAvg ? <span>{row.redsAvg}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.fouls ? <span>{row.fouls}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.foulsP90 ? <span>{row.foulsP90}</span> : <span>0</span>}
                                                </TableCell>
                                                <TableCell className={'league-stats-table-td'} align="center">
                                                    {row.foulsAvg ? <span>{row.foulsAvg}</span> : <span>0</span>}
                                                </TableCell>

                                            </>
                                        )
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default LeaguePlayerStatsTable;
