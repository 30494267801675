import React, {useContext, useState} from 'react';
import padLock from '../../../assets/icons/padlock.png'
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../context/AppContext";

function MatchDetailsStatsEvent(props) {
    const {isPro} = useContext(AppContext);

    const {matchDetailTeamData, matchTeamsDetail, i18String} = props;
    console.log('matchTeamsDetail', matchTeamsDetail);
    const navigate = useNavigate();

    const [homeStats, setHomeStats] = useState("home");
    const [awayStats, setAwayStats] = useState("away");

    const handleHomeChange = (type) => {
        setHomeStats(type);
    };

    const handleAwayChange = (type) => {
        setAwayStats(type);
    };

    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    const getBackgroundColor = (value) => {
        const number = parseFloat(value.replace('%', ''));

        if (number > 90) {
            return 'var(--H3Green90)';
        } else if (number > 60) {
            return 'var(--H3Green60)';
        } else {
            return 'white';
        }
    };

    const getStatValue = (stats, team) => {
        switch (team) {
            case 'home':
                return homeStats === "general" ? stats.hoverall : homeStats === "home" ? stats.hhome : stats.haway;
            case 'away':
                return awayStats === "general" ? stats.aoverall : awayStats === "away" ? stats.aaway : stats.ahome;
            default:
                return stats.hoverall;
        }
    };

    return (
        <div className={'stats-section'}>

            <div className='match-details-stats-check-box-section'>
                {/*HOME*/}
                <div className='match-details-stats-check-box-container'>
                    <div className='team-side-container'>
                        <div
                            onClick={() => {
                                window.open(`/team-detail/${matchTeamsDetail.homeId}`)
                            }}
                            className='match-details-stats-event-title-team-and-logo'>
                            <img
                                src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                alt={matchTeamsDetail.home}
                                style={{width: '25px', height: '25px'}}
                            />
                            <span className='match-details-stats-title-team-name'>{matchTeamsDetail.home}</span>
                        </div>
                    </div>

                    <div className='match-details-stats-check-box-label-container'>
                        <label className='match-details-stats-check-box-label'>
                            <input
                                type="checkbox"
                                name="homeStats"
                                value="general"
                                className='stats-check-box'
                                checked={homeStats === "general"}
                                onChange={() => handleHomeChange("general")}
                            />
                            General
                        </label>
                        <label className='match-details-stats-check-box-label'>
                            <input
                                type="checkbox"
                                name="homeStats"
                                value="home"
                                className='stats-check-box'
                                checked={homeStats === "home"}
                                onChange={() => handleHomeChange("home")}
                            />
                            Home
                        </label>
                        <label className='match-details-stats-check-box-label'>
                            <input
                                type="checkbox"
                                name="homeStats"
                                value="away"
                                className='stats-check-box'
                                checked={homeStats === "away"}
                                onChange={() => handleHomeChange("away")}
                            />
                            Away
                        </label>
                    </div>
                </div>

                {/*AWAY*/}
                <div className='match-details-stats-check-box-container'>

                    <div className='team-side-container team-side-container-for-away'>
                        <div
                            onClick={() => {
                                window.open(`/team-detail/${matchTeamsDetail.awayId}`)
                            }}
                            className='match-details-stats-event-title-team-and-logo'
                        >
                            <span className='match-details-stats-title-team-name' style={{paddingRight: '5px'}}>{matchTeamsDetail.away}</span>
                            <img
                                src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.awayId}.png`}
                                alt={matchTeamsDetail.away}
                                style={{width: '25px', height: '25px'}}
                            />
                        </div>
                    </div>

                    <div className='match-details-stats-check-box-label-container team-side-container-for-away'>
                        <label className='match-details-stats-check-box-label'>
                            <input
                                type="checkbox"
                                name="awayStats"
                                value="general"
                                className='stats-check-box'
                                checked={awayStats === "general"}
                                onChange={() => handleAwayChange("general")}
                            />
                            General
                        </label>
                        <label className='match-details-stats-check-box-label'>
                            <input
                                type="checkbox"
                                name="awayStats"
                                value="home"
                                className='stats-check-box'
                                checked={awayStats === "home"}
                                onChange={() => handleAwayChange("home")}
                            />
                            Home
                        </label>
                        <label className='match-details-stats-check-box-label'>
                            <input
                                type="checkbox"
                                name="awayStats"
                                value="away"
                                className='stats-check-box'
                                checked={awayStats === "away"}
                                onChange={() => handleAwayChange("away")}
                            />
                            Away
                        </label>
                    </div>
                </div>
            </div>

            {
                matchDetailTeamData && matchDetailTeamData.map(match => {
                    return (
                        <div className='match-detail-stats-title-section' key={match.name}>
                            <div className='match-detail-stats-title-container'>
                                <span className='match-detail-stats-title'>{i18String(match.name)}</span>
                            </div>
                            <div className={'stats-general-container'}>
                                {
                                    match.stats.map(stats => {
                                        return (
                                            <div className={'match-detail-stats-container'}>
                                                {
                                                    isPro ?
                                                        <div style={{backgroundColor: getBackgroundColor(stats.hhome)}}
                                                             className={'match-detail-stats'}>{getStatValue(stats, 'home')}</div>
                                                        :
                                                        <img onClick={() => toJoinPremium()} className='pad-lock-icon'
                                                             src={padLock} alt=""/>
                                                }

                                                <div className={'match-detail-stats-name'}>{i18String(stats.name)}</div>

                                                {
                                                    isPro ?
                                                        <div style={{backgroundColor: getBackgroundColor(stats.aaway)}}
                                                             className={'match-detail-stats'}>{getStatValue(stats, 'away')}</div>
                                                        :
                                                        <img onClick={() => toJoinPremium()} className='pad-lock-icon'
                                                             src={padLock} alt=""/>
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default MatchDetailsStatsEvent;
