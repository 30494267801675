import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchTeamFixtures} from "../../../api";
import {colorTheme} from "../../ColorCss";
import dayjs from "dayjs";
import noFlagIcon from "../../../assets/icons/noFlagIcon.png";
import {saveMatchToLocalStorage} from "../../local-storage/ManageLocalStorage";

function TeamFixtures(props) {

    const params = useParams();

    const {side, teamDetailsData, toggleBottomTabs} = props;

    const [fixturesData, setFixturesData] = useState(null);

    useEffect(() => {
        fetchTeamFixtures(params.id).then(data => {
            setFixturesData(data.data.data);
        })
    }, [params.id]);

    if (!fixturesData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    const dateForMatch = value => {
        return dayjs(value).format('DD.MM.YYYY');
    };

    const teamId = teamDetailsData?.id;
    const filterMatchesBySide = (league) => {
        if (fixturesData) {
            return league?.matches.filter((match) => {
                if (side === 'home') {
                    return match.homeId === teamId
                } else if (side === 'away') {
                    return match.awayId === teamId
                } else if (side === 'all') {
                    return match
                }
            })
        }
    }
    filterMatchesBySide();

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div>
                {/*MATCHES BY LEAGUE*/}
                {
                    toggleBottomTabs === 0 &&
                    <div>
                        {
                            fixturesData &&
                            fixturesData.matchesByLeague.map(league => {
                                return (
                                    <div>
                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            <tr className='team-detail-results-table-header'>
                                                <div className='team-details-results-date-container'>
                                                    <img className="results-and-fixtures-country-flag"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={league.area}/>
                                                    <a  target={"_blank"}
                                                        href={`/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${league.leagueId}/${league.seasonId}/stats`}
                                                        className='team-detail-results-area'>{league.area} {league.league}</a>
                                                </div>
                                                <div></div>
                                                <div></div>
                                                <div></div>

                                                <div></div>
                                                <div></div>
                                            </tr>
                                        </table>

                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            {
                                                filterMatchesBySide(league).map((match, index) => {
                                                    return (
                                                        <tr
                                                            style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}
                                                            className='team-detail-fixtures-table-row-container'>
                                                            <div className='results-match-date'>{dateForMatch(match.date)}</div>

                                                            <div className='team-detail-results-home-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.homeId}`}
                                                                        style={{color: match.homeId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-home-team'>
                                                                        {match.home}
                                                                    </a>
                                                            </div>

                                                            <div
                                                                onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                                                    matchId: match.id,
                                                                    home: match.home,
                                                                    homeId: match.homeId,
                                                                    away: match.away,
                                                                    awayId: match.awayId,
                                                                    time: match.dateTimeUtc,
                                                                    seasonId: match.seasonId
                                                                })}
                                                            >
                                                                <a className='team-detail-results-score'>{match.hscore} - {match.ascore}</a>
                                                            </div>

                                                            <div className='team-detail-results-away-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.awayId}`}
                                                                        style={{color: match.awayId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-away-team'>
                                                                        {match.away}
                                                                    </a>
                                                            </div>

                                                            <div></div>

                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {/*MATCHES BY TIME*/}
                {
                    toggleBottomTabs === 1 &&
                    <div>
                        {
                            fixturesData &&
                            fixturesData.matchesByTime.map(league => {
                                return (
                                    <div>
                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            <tr className='team-detail-results-table-header'>
                                                <div className='team-details-results-date-container'>
                                                    <img className="results-and-fixtures-country-flag"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={league.area}/>
                                                    <a  target={"_blank"}
                                                        href={`/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${league.leagueId}/${league.seasonId}/stats`}
                                                        className='team-detail-results-area'>{league.area} {league.league}</a>
                                                </div>

                                                <div></div>
                                                <div></div>
                                                <div></div>

                                                <div></div>
                                                <div></div>
                                            </tr>
                                        </table>

                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            {
                                                filterMatchesBySide(league).map((match, index) => {
                                                    return (
                                                        <tr
                                                            style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}
                                                            className='team-detail-fixtures-table-row-container'>
                                                            <div className='results-match-date'>{dateForMatch(match.date)}</div>

                                                            <div className='team-detail-results-home-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.homeId}`}
                                                                        style={{color: match.homeId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-home-team'>
                                                                        {match.home}
                                                                    </a>
                                                            </div>

                                                            <div
                                                                onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                                                    matchId: match.id,
                                                                    home: match.home,
                                                                    homeId: match.homeId,
                                                                    away: match.away,
                                                                    awayId: match.awayId,
                                                                    time: match.dateTimeUtc,
                                                                    seasonId: match.seasonId
                                                                })}
                                                            >
                                                                <a className='team-detail-results-score'>{match.hscore} - {match.ascore}</a>
                                                            </div>

                                                            <div className='team-detail-results-away-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.awayId}`}
                                                                        style={{color: match.awayId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-away-team'>
                                                                        {match.away}
                                                                    </a>
                                                            </div>

                                                            <div></div>

                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
        </div>
    );
}

export default TeamFixtures;
