import React, {useContext} from 'react';
import './Linear.css';
import crownIcon from "../../assets/icons/crown.png";
import {AppContext} from "../../context/AppContext";

function LinearComponent(props) {
    const {isPro} = useContext(AppContext);

    const color1 = '#410CD9';
    const color2 = '#DA45E5';

    return (
        !isPro && (
            <div className='general-linear-section'>
                <a
                    href={'/join-premium'}
                    style={{background: `linear-gradient(to right,  ${color1} 0%,${color2} 100%)`}}
                    className='linear-container'
                >
                    <img className="premium-icon" src={crownIcon} alt="Premium"/>
                    <span className='linear-container-text'>
                    Join Premium. Unlock Everything. No Ads. 🚀
                </span>
                </a>
            </div>
        )
    );
}

export default LinearComponent;
