import React, {useContext, useEffect, useState} from 'react';
import {fetchTrendMatches} from "../../api";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {AppContext} from "../../context/AppContext";
import padLock from "../../assets/icons/padlock.png";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";
import dayjs from "dayjs";
import {saveMatchToLocalStorage} from "../local-storage/ManageLocalStorage";

function TrendMatchesContent(props) {
    const {isPro, timeZone, i18String} = useContext(AppContext);

    const {currentDate, type, selection} = props;

    const [trendMatchesData, setTrendMatchesData] = useState(null);
    const [originalData, setOriginalData] = useState(null);

    useEffect(() => {
        fetchTrendMatches(type, currentDate).then((data) => {
            const tmData = data.data.data;
            setTrendMatchesData(tmData);
            setOriginalData(tmData);
        });
    }, [currentDate]);

    useEffect(() => {
        if (selection === 'top' && originalData) {
            const filteredData = originalData.filter(match => match.top === true);
            setTrendMatchesData(filteredData);
        } else if (selection === 'priority' && originalData) {
            const filteredData = originalData.filter(match => match.priority !== null && match.priority !== undefined);
            setTrendMatchesData(filteredData);
        } else if (selection === 'all' && originalData) {
            setTrendMatchesData(originalData);
        }
    }, [selection, originalData]);

    const tableHeader = [
        {text: `${i18String('League')}`, value: 'league', align: 'start', sortable: false, width: '20%'},
        {text: `${i18String('Date')}`, value: 'date', width: '20%', align: 'center'},
        {text: `${i18String('Match')}`, value: 'match', width: '20%', align: 'center'},
        {text: `${i18String('FT')}`, value: 'ft', width: '20%', align: 'center'},
        {text: `${i18String('HT')}`, value: 'ht', width: '20%', align: 'center'},
        {text: `${i18String('Avg')} %`, value: 'home', width: '10%', align: 'center'},
    ]

    const mobileTableHeader = [
        {text: `${i18String('League')}`, value: 'leauge', align: 'start', sortable: false},
        {text: `${i18String('Avg')} %`, value: 'home', align: 'center'},
    ]

    const statsColor = (stats) => {
        if (stats > 90) {
            return {
                background: '#72da2373',
                color: '#095035'
            }
        } else if (stats > 80) {
            return {
                background: '#72da233d',
                color: '#095035'
            }
        } else if (stats > 50) {
            return {
                background: '#f6f7cd',
                color: '#656500'
            }
        } else {
            return {
                background: '#ffe9e6',
                color: '#ff7563'
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    const matchDate = (value) => {
        const today = new Date().toISOString().substr(0, 10);
        const nextDay = dayjs(today).add(1, 'days').format('YYYY-MM-DD');
        const getDate = new Date(value).toISOString().substr(0, 10);
        const getDateHour = dayjs(value).tz(timeZone).format('HH:mm');

        return `${dayjs(value).format("D.M.YYYY")} ${getDateHour}`;
        /*if (today === getDate) {
            return `${i18String('Today')} ${getDateHour}`
        } else if (getDate === nextDay) {
            if (lang === 'en') {
                return `Tomorrow ${getDateHour}`
            } else {
                return `Yarın ${getDateHour}`
            }
        } else {
            return `${dayjs(value).format("ddd DD MMM")} ${getDateHour}`;
        }*/
    }

    return (
        <div>
            {
                trendMatchesData !== null ?
                    <div>
                        {/*WEB VIEW*/}
                        <div className={'trend-matches-web-view'}>
                            <TableContainer>
                                <Table className="trend-matches-table-section" size={"medium"}
                                       sx={{minWidth: 250}}
                                       aria-label="simple table">
                                    <TableHead acitve={true}>
                                        <TableRow className="trend-matches-table-headers">
                                            {
                                                tableHeader.map((header) => {
                                                    return (
                                                        <TableCell
                                                            sortDirection={header.text}> {header.text}</TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {trendMatchesData && trendMatchesData.map((teamStat, rowIndex) => {
                                        return (
                                            <TableBody className="table-body-section">
                                                {
                                                    rowIndex > 4 && !isPro ?
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="trend-matches-locked-row"
                                                            style={rowIndex % 2 ? {background: "var(--H3Light1)"} : {background: "var(--H3Light0)"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                {/*<img className='pad-lock-icon' src={padLock} alt=""/>*/}
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                {/*<img className='pad-lock-icon' src={padLock} alt=""/>*/}
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="trend-matches-content-row"
                                                            style={rowIndex % 2 ? {background: "var(--H3Light1)"} : {background: "var(--H3Light0)"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="left">
                                                                <div className={'flag-area-section'}>
                                                                    <img className="country-flag"
                                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${teamStat.areaId}.png`}
                                                                         onError={(e) => onImageError(e)}
                                                                         alt="country"/>
                                                                    <div className="trend-matches-area-container">
                                                                        <span
                                                                            className="trend-matches-area-name">{teamStat.areaName} - </span>
                                                                        <span
                                                                            className="trend-matches-competition-name">{teamStat.competitionName}</span>
                                                                    </div>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="left">
                                                                <div style={{textAlign: 'center'}}>
                                                                    <span
                                                                        className="best-teams-date-time-and-btts-texts">{matchDate(teamStat.dateTimeUtc)}</span>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="teams-name-container">
                                                                    <a target={"_blank"}
                                                                       href={`/team-detail/${teamStat.homeTeamId}`}
                                                                       className={'trend-matches-name trend-matches-home-team'}>{teamStat.homeTeam}</a>
                                                                    <a
                                                                        onClick={() => saveMatchToLocalStorage(`teams-state:${teamStat.id}`, {
                                                                            matchId: teamStat.id,
                                                                            home: teamStat.homeTeam,
                                                                            homeId: teamStat.homeTeamId,
                                                                            away: teamStat.awayTeam,
                                                                            awayId: teamStat.awayTeamId,
                                                                            time: teamStat.dateTimeUtc,
                                                                            seasonId: teamStat.seasonId
                                                                        })}
                                                                        className={'trend-matches-name-line'}> - </a>
                                                                    <a target={"_blank"}
                                                                       href={`/team-detail/${teamStat.awayTeamId}`}
                                                                       className={'trend-matches-name'}>{teamStat.awayTeam}</a>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div>
                                                                    {
                                                                        teamStat?.st === 'FT' && <span
                                                                            className='trend-matches-ht-score'>{teamStat?.hscoreFT} - {teamStat?.ascoreFT}</span>
                                                                    }
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div>
                                                                    {
                                                                        teamStat?.st === 'FT' && <span
                                                                            className='trend-matches-ht-score'>{teamStat?.hscoreHT} - {teamStat?.ascoreHT}</span>
                                                                    }
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="percent-cell-container"
                                                                     style={statsColor(teamStat.avgRate)}>
                                                                    <span
                                                                        className="trend-matches-percent-text">{teamStat.avgRate} %</span>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        )
                                    })
                                    }
                                </Table>
                            </TableContainer>

                            {
                                trendMatchesData?.length === 0 &&
                                <div className='no-filter-selection-info-text-container'>
                                    <span
                                        className='no-filter-selection-info-text'> {i18String('No matches were found matching your filter selection.')}</span>
                                </div>
                            }
                        </div>

                        {/*MOBILE VIEW*/}
                        <div className={'trend-matches-mobile-view'}>
                            <TableContainer>
                                <Table className="trend-matches-table-section"
                                       aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="trend-matches-table-headers">
                                            {
                                                mobileTableHeader.map((header) => {
                                                    return (
                                                        <TableCell
                                                            className='trend-matches-mobile-td-header'
                                                            sortDirection={header.text}> {header.text}</TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {trendMatchesData && trendMatchesData.map((teamStat, rowIndex) => {
                                        return (
                                            <TableBody className="table-body-section">
                                                {
                                                    rowIndex > 4 && !isPro ?
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="best-team-locked-row"
                                                            style={rowIndex % 2 ? {background: "#f3f3f3"} : {background: "white"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="trend-matches-row"
                                                            style={rowIndex % 2 ? {background: "#f3f3f3"} : {background: "white"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="left">
                                                                <div className='team-container'>
                                                                    <div style={{display: 'grid'}}>
                                                                        <div className={'flag-area-section'}>
                                                                            <img className="country-flag"
                                                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${teamStat.areaId}.png`}
                                                                                 onError={(e) => onImageError(e)}
                                                                                 alt="country"/>
                                                                            <div
                                                                                className="trend-matches-area-container">
                                                                                <span
                                                                                    className="trend-matches-area-name">{teamStat.areaName} - </span>
                                                                                <span
                                                                                    className="trend-matches-competition-name">{teamStat.competitionName}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="teams-name-container">
                                                                            <a
                                                                                onClick={() => saveMatchToLocalStorage(`teams-state:${teamStat.id}`, {
                                                                                    matchId: teamStat.id,
                                                                                    home: teamStat.homeTeam,
                                                                                    homeId: teamStat.homeTeamId,
                                                                                    away: teamStat.awayTeam,
                                                                                    awayId: teamStat.awayTeamId,
                                                                                    time: teamStat.dateTimeUtc,
                                                                                    seasonId: teamStat.seasonId
                                                                                })}
                                                                                className={'trend-matches-name'}>{teamStat.homeTeam} - {teamStat.awayTeam}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="percent-cell-container"
                                                                     style={statsColor(teamStat.avgRate)}>
                                                                    <span
                                                                        className="trend-matches-percent-text">{teamStat.avgRate} %</span>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        )
                                    })
                                    }
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    :
                    <div className="const_width loading-container">
                        <div>
                            <div className="loading blink"></div>
                            <div>
                                Loading...
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default TrendMatchesContent;
