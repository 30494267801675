import React from 'react';

function StatsHeader(props) {

    const statStyleWidth = () => {
        if (props.currentStats === 'oddsHeader') {
            return 'stats-header-odds'
        } else {
            return 'stats-header-other'
        }
    }

    return (
        <td className={statStyleWidth()}>
            <div className='stats-title-container'>
                <span className="table-stats-header">{props.stHeader.text}</span>
            </div>
        </td>
    );
}

export default StatsHeader;
