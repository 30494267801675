import React, {useContext} from 'react';
import {AppContext} from "../../context/AppContext";

function Contact(props) {

    const {i18String} = useContext(AppContext);

    return (
        <div className="const_width">
            <div className="content_container">
                <p className="heading">{i18String.Contact}</p>
                <div className="mx-auto">
                    <p className="content-text">
                        <strong>Stats365.com</strong>'da yer alan içerikler ve diğer konular hakkında her türlü
                        soru, görüş ve
                        önerileriniz için bize ulaşabilirsiniz.
                    </p>
                    <br/>
                        <p className="content-text">
                            <strong>{i18String.Email}:</strong><span className="link_color"><a
                            href="mailto:support@stats365.com"> support@stats365.com</a>
                    </span>
                        </p>
                        <p className="content-text">
                            <strong>{i18String.Address}:</strong> 27 Old Gloucester Street, London, England, WC1N 3AX
                        </p>
                        <p className="content-text">
                            <strong>Twitter:</strong> <span className="link_color"><a
                            href="https://twitter.com/stats365" target="_blank">
                        https://twitter.com/stats365</a></span>
                        </p>
                        <p className="content-text">
                            <strong>Facebook:</strong> <span className="link_color"><a
                            href="https://www.facebook.com/stats365/" target="_blank">
                        https://www.facebook.com/stats365/</a></span>
                        </p>
                        <p className="content-text">
                            <strong>Instagram:</strong> <span className="link_color"><a
                            href="https://www.instagram.com/stats365/" target="_blank">
                        https://www.instagram.com/stats365/</a></span>
                        </p>
                </div>
            </div>
        </div>
    );
}

export default Contact;
