import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchLeagueFixtures} from "../../../api";
import {AppContext} from "../../../context/AppContext";

function Fixtures(props) {
    const {i18String} = useContext(AppContext);

    const params = useParams();

    const [fixturesData, setFixturesData] = useState(null);

    useEffect(() => {
        fetchLeagueFixtures(params.seasonId).then(data => {
            setFixturesData(data.data.data);
        })
    }, [params.seasonId]);

    if (!fixturesData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{width: '100%'}}>
            {
                fixturesData &&
                fixturesData.map(rounds => {
                    return (
                        <div>
                            <div className='rounds-container'>
                                <span className='rounds-text'>{rounds.round}. {i18String('Week')}</span>
                            </div>
                            <div>
                                {
                                    rounds.matches.map(match => {
                                        return (
                                            <div>
                                                <table style={{width: '100%', borderSpacing: 0}}>
                                                    <tr className='fixtures-table-row-container'>

                                                        <div className='fixtures-date-container'>
                                                            {match.date}
                                                        </div>

                                                        <div className='results-home-container'>
                                                            <a target={"_blank"} href={`/team-detail/${match.homeId}`}
                                                               className='results-team-name'>{match.home}</a>
                                                        </div>

                                                        <div className='fixtures-versus-container'>
                                                            <span className='fixtures-versus-text'>V</span>
                                                        </div>

                                                        <div className='results-away-container'>
                                                            <a target={"_blank"} href={`/team-detail/${match.awayId}`}
                                                               className='results-team-name'>{match.away}</a>
                                                        </div>
                                                    </tr>
                                                </table>


                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Fixtures;
